.skip-to-main {
  block-size: 1px;
  inline-size: 1px;
  inset-block-start: auto;
  inset-inline-start: -999px;
  overflow: hidden;
  position: absolute;
  z-index: -999;
    
  &:focus,
 
  &:active,
  &:hover {
    @include menu-font;
    block-size: auto;
    border: 0;
    color: #fff;
    inline-size: auto;
    inset-block-start: 30px;
    inset-inline-start: 10px;
    line-height: 1.2;
    margin: 0;
    overflow: auto;
    padding: 5px;
    text-align: start;
    z-index: 999;
  }
}
@media only screen and (max-width: 64em) {
  .skip-to-main {
    display: none;
  }
}
  
.light {
  .skip-to-main {
    &:focus,
 
    &:active,
    &:hover {
      color: #2d3a4a;
    }
  }
}
