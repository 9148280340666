// *** SEARCH FIELD STYLES *** //
.search {
  display: block;
  inline-size: 100%;
  margin-block: rem-calc(42) rem-calc(10);
  position: relative;

  .no-top-margin & {
    margin-block-start: rem-calc(10);
  }

  .column {
    position: relative;
  }
}

.search-field,
.search-field[readonly] {
  @include displayXXS-font;
  background-color: transparent;
  background-image: url("/assets/images/Searchicon.png");
  background-position: center right;
  background-repeat: no-repeat;
  background-size: 0.85em;
  border-block-end: 3px solid $secondary-color;
  border-block-start: none;
  border-inline-end: none;
  border-inline-start: none;
  box-shadow: none;
  box-sizing: content-box;
  color: $white !important;
  font-family: NHaasGroteskDSStd-45Lt, Helvetica, Arial, sans-serif;
  font-size: 1.625rem;
  letter-spacing: 0.4px;
  padding: rem-calc(12 0 10 0);
  z-index: 9999;
  position: relative;

  &.js-animating {
    padding-inline-end: 0;
  }

  &.morph-into-modal,
  &.ng-empty {
    color: #fff;
  }

  &::-ms-clear {
    display: none;
  }

  &:focus {
    background-color: transparent;
    border-block-end: 3px solid $secondary-color;
    border-block-start: none;
    border-inline-end: none;
    border-inline-start: none;
    box-shadow: none;
  }
  @media all and (max-width: 640px) {
    block-size: 30px;
  }
}

[data-whatinput="keyboard"] .search-field:focus {
  outline: -webkit-focus-ring-color auto 1px;
}

input.search-field::placeholder {
  color: #fff;
  opacity: 1;
}

input.search-field:input-placeholder {
  color: #fff;
}

input.search-field::input-placeholder {
  color: #fff;
}

.fa-search {
  color: $light-gray;
  display: none !important;
  font-size: 20px !important;
  inset-block-start: 24px;
  inset-inline-end: 15px;
  position: absolute;
  @media all and (max-width: 640px) {
    font-size: 16px !important;
    inset-block-start: 5px;
    inset-inline-end: 8px;
  }
}

// *** ADVANCED SEARCH  STYLES *** //
.search-options {
  margin-block-end: rem-calc(15);
  padding: 0;

  ul {
    margin: 0;
  }

  li {
    a {
      @include body8-font;
      color: $white !important;
      padding: 0 0 15px;
    }
  }

  .toggle-title {
    text-decoration: none;

    &::after {
      color: $secondary-color;
      content: "+";
      font-size: rem-calc(16);
      margin-inline-start: 5px;
      position: absolute;
    }
  }

  .is-accordion-submenu-parent[aria-expanded="true"] {
    .toggle-title {
      @include body7-font;

      &::after {
        color: $secondary-color;
        content: "-";
        inset-block-start: 0;
        margin-inline-start: 5px;
        position: absolute;
        position: relative;
      }
    }
  }
}

.search-sort {
  margin-block-end: rem-calc(60);
  @include breakpoint(small only) {
    margin-block-end: rem-calc(20);
  }

  select {
    background-position: right 0 top 7px;
    border: none;
    font-size: rem-calc(14);
    padding-inline-start: 0;

    &:focus {
      outline-color: transparent;
      outline-style: none;
    }
  }

  .search-sort-list {
    display: block;
    margin: 0;
    padding: 0;

    li {
      @include body8-font;
      display: inline-block;
      list-style: none;
      padding-inline-end: rem-calc(55);

      a {
        color: $white;

        &:hover {
          @include body7-font;
        }

        &.active {
          @include body7-font;
        }
      }
    }
  }
}

.transform-position,
.transform-office,
.transform-year,
.transform-school,
.transform-practice,
.transform-industry {
  transition: all 0.15s ease;
}

.expand {
  max-block-size: 600px;

  &::before {
    content: url("/assets/images/dropdownarrow_up.png");
    display: block;
    inset-block-start: 0;
    inset-inline-end: 3%;
    pointer-events: none;
    position: absolute;
  }
}

a:any-link {
  color: none;
  //    text-decoration: none;
  cursor: auto;
  // &:focus {
  //     outline: none;
  // }
}

// *** ADVANCED SEARCH - TAG STYLES *** //
.advanced-options {
  .search-tags {
    margin: rem-calc(8 0 10 0);

    .tag {
      @include body10-font;
      background: #344458;
      border-radius: 0;
      color: $white;
      cursor: default;
      display: inline-block;
      margin: rem-calc(0 10 6 0);
      padding: 0.33333rem 0.5rem;
      white-space: nowrap;

      .close {
        color: $white;
        font-size: rem-calc(10);
        margin-inline-start: 5px;
        text-decoration: none;

        &:hover {
          color: $secondary-color !important;
        }
      }
    }

    .remove {
      display: none;
    }
  }

  .clear-button {
    @include body10-font;
    color: $white;
    display: block;
    margin-block: rem-calc(10) rem-calc(5);

    a {
      color: inherit;
      padding: 0;
      text-decoration: none;
    }
  }

  .advanced-date-range {
    margin-block-start: rem-calc(10);
  }

  label {
    @include body10-font;
  }
}

.advanced-options {
  display: block;
}

.search-tabs {
  .tabs,
  .tabs.on-dark {
    margin-block-start: 0;

    .tabs-title {
      &,
      &:last-child {
        padding-block-end: 2em;
      }
    }
  }
}

.search-results,
.default-search-results {
  margin-block-start: rem-calc(20);
}

.results-desc {
  p {
    @include body3-font;
  }
}

.search-result {
  color: $text-link-blue;
  display: block;
  inline-size: 100%;
  margin-block-end: rem-calc(35);
  position: relative;

  .highlighted {
    font-weight: bold;
  }

  .result-block {
    display: block;
    padding-block-end: rem-calc(20);
  }

  .result-name {
    h4 {
      margin-block-end: 5px;

      &:hover {
        color: #ccc;
      }

      span {
        @include body7-font;
      }
    }
  }

  .result-title,
  p,
  a {
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    margin-block-end: 0;
  }

  .result-title {
    @include displayXXS-font;
    color: $white;
    line-height: 1.2;
    margin-bottom: 15px;

    a {
      color: $white;

      &:hover,
      &:focus {
        color: #ccc;
      }
    }
  }

  p {
    @include body8-font;
    letter-spacing: 0.6px;
    @include breakpoint(small only) {
      font-size: rem-calc(13);
      line-height: rem-calc(22);
    }

    a {
      color: $light-gray;

      &:hover {
        color: $white;
      }
    }
  }
}

.colorwhite {
  color: $light-blue !important;
}

.advance-search {
  .close {
    display: none;
  }

  .open {
    display: block;
  }
}
