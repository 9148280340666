.advanced-search {
  display: flex;
  flex-flow: column;
  margin-block-start: 42px;

  strong {
    font-weight: unset;
  }

  .thefirm &,
  .insights & {
    .advanced-search__results-item-paragraph {
      color: #fafcfe;
    }
  }
}

.advanced-search__no-results-message {
  padding-bottom: 35px;

  p {
    font-family: NHaasGroteskDSStd-55Rg, Helvetica, Arial, sans-serif;
    font-size: 0.875rem;
    letter-spacing: 0.4px;
    line-height: 1.6;
    letter-spacing: 0.6px;
  }
}

.advanced-search__search-box {
  input {
    background: transparent;
    background-image: url("/assets/images/Searchicon.png");
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: 20px;
    block-size: 2.1875rem;
    border: 0;
    border-block-end: 3px solid #f8e71c;
    box-shadow: none;
    box-sizing: content-box;
    color: #fefefe;
    font-family: "NHaasGroteskDSStd-45Lt", Helvetica, Arial, sans-serif;
    font-size: 1.625rem;
    letter-spacing: 0.4px;
    padding: 12px 0 10px;
    margin-block-end: 23px;

    &:focus {
      background-color: transparent;
      border: 0;
      border-block-end: 3px solid #f8e71c;
      box-shadow: none;
    }
  }
}

.advanced-search__facets-label {
  cursor: pointer;
  font-family: "NHaasGroteskDSStd-55Rg", Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0.4px;
  line-height: 1.6;

  [aria-expanded="false"] & {
    margin-block-end: 0;
  }

  &::after {
    color: #f8e71c;
    font-size: 1rem;
    margin-inline-start: 5px;
  }

  .advanced-search__facets[aria-expanded="true"] & {
    font-weight: bold;
  }

  .advanced-search__facets[aria-expanded="true"] &::after {
    content: "-";
  }

  .advanced-search__facets[aria-expanded="false"] &::after {
    content: "+";
  }
}

.advanced-search__results-container {
  inline-size: 100%;
  min-block-size: 1px;
}

.advanced-search__results-item {
  a {
    color: #fefefe;
    text-decoration: none;

    &:hover {
      color: #ccc;
    }
  }

  & + .advanced-search__results-item {
    margin-block-start: 35px;
  }
}

.advanced-search__results-item-category {
  font-family: NHaasGroteskDSStd-65Md, Helvetica, Arial, sans-serif;
  font-size: 0.75rem;
  letter-spacing: 1px;
  margin-block-end: 0.625rem;
  text-transform: uppercase;
  margin-block-end: 10px;
  display: flex;
}

.advanced-search__results-item-title {
  color: inherit;
  font-size: 1.625rem;
  letter-spacing: 0.4px;
  margin-block-end: 0;
}

.advanced-search__results-item-link {
  * {
    pointer-events: none;
  }

  &:hover {
    h4 {
      color: #ccc;
    }
  }
}

.advanced-search__results-details {
  font-family: NHaasGroteskDSStd-55Rg, Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0.4px;
  letter-spacing: 0.6px;
  line-height: 1.6;
  margin-block-end: 0;
  .advanced-search__results-container & {
    color: #c7cacf;
  }
}

.advanced-search__results-item-paragraph {
  font-family: NHaasGroteskDSStd-55Rg, Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0.4px;
  letter-spacing: 0.6px;
  line-height: 1.6;
  .advanced-search__results-container & {
    color: #c7cacf;
  }
}

.loader-container {
  display: flex;
  inline-size: 100%;
  justify-content: center;
}

.loader {
  animation: linearMove infinite 2s linear;
  background: #ccc;
  block-size: 5px;
  inline-size: 2.5em;
}

@keyframes linearMove {
  0%,
  100% {
    transform: translate(-60px, 0);
  }

  50% {
    background: white;
    transform: translate(60px, 0);
  }
}

.advanced-search__facets {
  margin-block-end: 40px;

  &[aria-expanded="true"] .advanced-search__facets-panel {
    block-size: auto;
    overflow: visible;
  }
}

.advanced-search__facets-panel {
  block-size: 0;
  overflow: hidden;
}

.advanced-search__facets-container {
  display: flex;
  flex-flow: wrap;
  gap: 20px;

  @include breakpoint(small down) {
    flex-flow: column;
  }
}

.advanced-search__facet-container {
  background-image: url("/assets/images/dropdownarrow2.png");
  background-position: center right 0.65em;
  background-repeat: no-repeat;
  border: 1px solid #fefefe;
  border-radius: 0;
  cursor: pointer;
  display: flex;
  flex-flow: column;
  font-family: NHaasGroteskDSStd-55Rg, Helvetica, Arial, sans-serif;
  font-size: 0.75rem;
  inline-size: calc(50% - 10px);
  letter-spacing: 0.6px;
  line-height: 1.8;
  padding: 5px 25px 5px 13px;
  position: relative;

  @include breakpoint(small down) {
    inline-size: 100%;
  }

  &[aria-expanded="true"] {
    .advanced-search__facet-dropdown {
      block-size: auto;
      border: 1px solid #fefefe;
      border-block-start: 0;
      max-block-size: 200px;
      overflow: auto;
      padding-block-end: 10px;
      z-index: 10;
    }
  }
}

.advanced-search__facet-label {
  margin-block-end: 0;
}

.advanced-search__facet-dropdown {
  background-color: #2d3a4a;
  block-size: 0;
  display: flex;
  flex-flow: column;
  inline-size: calc(100% + 2px);
  inset-block-start: 100%;
  inset-inline-start: -1px;
  overflow: hidden;
  position: absolute;
}

.advanced-search__facet {
  inline-size: 100%;

  &:first-child {
    padding-block-start: 10px;
  }

  & + .advanced-search__facet {
    padding-block-start: 10px;
  }
}

.advanced-search__subFacet-container {
  padding-inline-start: 20px;
}

.advanced-search__date-range {
  inline-size: 50%;
  margin-block-start: 20px;

  @include breakpoint(small down) {
    inline-size: 100%;
  }
}

.advanced-search__date-range-dropdown-container {
  background-image: url("/assets/images/dropdownarrow2.png");
  background-position: center right 0.65em;
  background-repeat: no-repeat;
  border: 1px solid #fefefe;
  border-radius: 0;
  cursor: pointer;
  display: flex;
  flex-flow: column;
  font-family: NHaasGroteskDSStd-55Rg, Helvetica, Arial, sans-serif;
  font-size: 0.75rem;
  inline-size: calc(100% - 10px);
  letter-spacing: 0.6px;
  line-height: 1.8;
  padding: 5px 25px 5px 13px;
  position: relative;

  @include breakpoint(small down) {
    inline-size: 100%;
  }

  &[aria-expanded="true"] {
    .advanced-search__date-range-dropdown {
      block-size: auto;
      border: 1px solid #fefefe;
      border-block-start: 0;
      z-index: 10;
      max-block-size: 250px;
      overflow: auto;
    }
  }
}

.advanced-search__date-range-dropdown {
  background-color: #2d3a4a;
  block-size: 0;
  display: flex;
  flex-flow: column;
  inline-size: calc(100% + 2px);
  inset-block-start: 100%;
  inset-inline-start: -1px;
  overflow: hidden;
  position: absolute;

  label {
    padding: 5px 25px 5px 13px;
  }
}

.advanced-search__date-range-wrapper {
  inline-size: 100%;

  @include breakpoint(small down) {
    &:first-child {
      margin-block-end: 10px;
    }
  }
}

.advanced-search__date-range-container {
  display: flex;
  inline-size: 100%;

  @include breakpoint(small down) {
    flex-flow: column;
  }
}

.advanced-search__date-range-placeholder {
  margin-block-end: 0;
}

.advanced-search__current-selection {
  display: flex;
  flex-flow: wrap;
  margin-block-start: 20px;
}

.advanced-search__current-selection__item-selected {
  background: #344458;
  border-radius: 0;
  color: #fefefe;
  cursor: default;
  display: inline-block;
  font-family: NHaasGroteskDSStd-55Rg, Helvetica, Arial, sans-serif;
  font-size: 0.75rem;
  letter-spacing: 0.6px;
  line-height: 1.8;
  margin: 0 0.625rem 0.375rem 0;
  padding: 0.33333rem 0.5rem;
  white-space: nowrap;
}

.advanced-search__current-selection__close-btn {
  color: #fefefe !important;
  font-family: NHaasGroteskDSStd-55Rg, Helvetica, Arial, sans-serif;
  font-size: 0.625rem;
  letter-spacing: 0.4px;
  line-height: 1.6;
  margin-inline-start: 5px;
  padding: 0;
}

.advanced-search__reset-button {
  color: #fefefe;
  display: block;
  font-family: NHaasGroteskDSStd-55Rg, Helvetica, Arial, sans-serif;
  font-size: 0.75rem;
  letter-spacing: 0.6px;
  line-height: 1.8;
  margin-block: 0.625rem 0.3125rem;
}

.advanced-search__tabs {
  display: flex;
  margin-block-end: 50px;

  @include breakpoint(small only) {
    display: none;
  }
}

.advanced-search__tab-item + .advanced-search__tab-item {
  padding-inline-start: 25px;
  @include breakpoint(small only) {
    padding-inline-start: 13px;
  }
}

.advanced-search__tab-item {
  color: #fefefe;
  cursor: pointer;
  font-family: NHaasGroteskDSStd-55Rg, Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0.4px;
  line-height: 1.6;
  text-decoration: none;

  @include breakpoint(small only) {
    font-size: 0.75rem;
    padding-inline-start: 13px;
  }

  &[data-selected-tab="true"] {
    font-family: NHaasGroteskDSStd-65Md, Helvetica, Arial, sans-serif;
  }
}

.advanced-search__tabs-dropdown {
  @extend .advanced-search__facet-container;
  display: none;
  margin-block: 10px;

  @include breakpoint(small down) {
    display: block;
    inline-size: 100%;
  }

  &[aria-expanded="true"] {
    .advanced-search__tabs-dropdown-list {
      block-size: auto;
      border: 1px solid #fefefe;
      border-block-start: 0;
      max-block-size: 200px;
      overflow: auto;
      padding-block-end: 10px;
      z-index: 10;
    }
  }
}

.advanced-search__tabs-label {
  @extend .advanced-search__facet-label;
}

.advanced-search__tabs-dropdown-list {
  @extend.advanced-search__facet-dropdown;
}

.highlighted {
  font-weight: 700;
}
