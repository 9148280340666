.circle-graph {
  $diameter: 180;
  block-size: rem-calc($diameter);
  inline-size: rem-calc($diameter);
  margin: -75px 0 0 20%;
  position: relative;
  visibility: hidden;
  @include breakpoint(small down) {
    margin: 0 auto 3rem;
  }

  &.js-animating {
    visibility: visible;
  }

  h2,
  h3 {
    display: block;
    inline-size: 100%;
    position: absolute;
  }

  h2 {
    @include displayXL-font;
    inset-block-start: 50%;
    margin-block-start: -0.5em;
  }

  h3 {
    @include body3-font;
    inset-block-start: 100%;
    inset-inline-start: 0;
    margin-block-start: 0.75rem;
    opacity: 0;
    transition: opacity 300ms ease;

    &.js-active {
      opacity: 1;
      transition-delay: 300ms;
    }
  }

  &::after {
    block-size: rem-calc($diameter - 6);
    border: 1px solid rgba($white, 0.3);
    border-radius: rem-calc($diameter);
    content: "";
    display: block;
    inline-size: rem-calc($diameter - 6);
    inset-block-start: rem-calc(3);
    inset-inline-start: rem-calc(3);
    position: absolute;
  }
}
