.main-footer {
  @include footer-font;
  bottom: 0;
  display: block;
  height: auto;
  padding: 12.5rem 22% 6.25rem 12%;
  position: relative;
  width: 100%;

  @include breakpoint(large down) {
    padding-left: 8%;
    padding-right: 8%;
  }

  @include breakpoint(medium down) {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 6.25rem;
  }

  @include breakpoint(small down) {
    padding-left: 6%;
    padding-right: 6%;
  }

  .biopage & {
    @include breakpoint(large up) {
      padding-right: 12%;
    }
  }

  &::after {
    clear: both;
    content: "";
    display: table;
  }

  .footer-copyright {
    display: inline-block;
    font-family: inherit;
    font-size: inherit;
    margin-bottom: 0;
    @include breakpoint(1200px down) {
      display: block;
    }

    &:lang(fr) {
      display: block;
    }

    span {
      font-size: 10px;
      margin-left: 2px;
    }
  }

  .footer-menu {
    display: inline-block;
    float: right;
    font-size: inherit;
    margin: 0;
    padding: 0;
    @include breakpoint(1200px down) {
      display: block;
      float: left;
    }

    li {
      display: inline-block;
      font-family: inherit;
      font-size: inherit;
      line-height: 1;
      list-style: none;
      margin-bottom: 0;
      padding: 0 0.66em 0 0;

      &:last-child {
        padding-right: 0;
      }

      a {
        color: $white;
        display: inline-block;
        text-decoration: underline;

        &:hover {
          color: $grey-hover;
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }

    .footer-social {
      @include breakpoint(small down) {
        margin-top: 0.5em;
        width: 100%;
      }

      a {
        margin-right: 10px;
        padding: 0;
        text-decoration: none;
        transition: opacity 150ms ease;
        @include breakpoint(medium down) {
          margin-top: 0.75em;
        }

        &:hover,
        &:focus {
          opacity: 0.5;
        }

        &:last-child {
          margin-right: 0;
        }

        img,
        svg {
          margin-bottom: -0.33em;
        }
      }
    }

    &:lang(fr) {
      display: block;
      float: left;
    }
  }

  .footer-copyright,
  .footer-menu li {
    @include breakpoint(medium down) {
      line-height: 1.5;
    }
    @include breakpoint(small down) {
      line-height: 2.2;
    }
  }
}

.white {
  .main-footer {
    .footer-menu {
      margin: 0;
      padding: 0;

      li {
        a {
          color: $primary-color;
          text-decoration: underline;

          &:hover {
            color: #4c627d;
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }

      .footer-social {
        a {
          margin-right: 10px;
          padding: 0;
          text-decoration: none;
          @media all and (max-width: 460px) {
            margin-top: 10px;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .FB,
    .TW,
    .GP,
    .LI {
      fill: $primary-color;
    }
  }
}

#transtion-contain + .main-footer {
  @include breakpoint(large down) {
    padding-left: 12%;
  }
}
