.inpage-sub-menu {
  inline-size: 100%;
  margin: 20px 0 30px;

  li {
    @include body8-font;
    display: inline-block;
    list-style: none;
    padding-inline-end: 60px;

    a {
      color: $white;

      &:hover {
        font-weight: normal;
      }
    }

    .active {
      font-weight: bold;
    }
  }
}

.inpage-list-menu {
  border-block-end: 1px $light-gray solid;
  border-block-start: 1px $light-gray solid;
  display: block;
  inline-size: 100%;
  margin-block-end: rem-calc(35);
  padding: rem-calc(20) 0 rem-calc(5) 0;
  @include breakpoint(small down) {
    padding: rem-calc(20) 0 0 0;
  }

  ul {
    margin: 0;

    li {
      @include body8-font;
      color: #828aa6 !important;
      font-weight: normal;
      list-style: none;
      padding-block-end: rem-calc(20);

      &:last-child {
        @include breakpoint(small down) {
          padding-block-end: rem-calc(20);
        }
      }

      a {
        color: $dark-blue !important;
        text-decoration: none;

        &:hover {
          color: $black !important;
        }
      }
    }
  }

  .is-active {
    @include body7-font;
    color: #2d3b4a !important;
  }
}

@include breakpoint(small down) {
  .mobile-subnav {
    background: #38485c;
    block-size: auto;
    border-block-start: 1px #38485c solid;
    display: block;
    font-size: rem-calc(14);
    inline-size: 100%;
    inset-block-start: 53px;
    inset-inline-start: 0;
    position: fixed;
    transition: left $menu-transition-duration $menu-transition-ease;
    z-index: 200;

    .js-show-navigation & {
      inset-inline-start: -$menu-size-medium;
      @include breakpoint(small down) {
        inset-inline-start: -$menu-size-small;
      }
      @include breakpoint(420px down) {
        inset-inline-start: -$menu-size-xsmall;
      }
    }

    li {
      display: block;
      float: inline-start;
      inline-size: 100%;
      padding-block-end: 0;

      a {
        @include body8-font;
        block-size: 50px;
        border-block-end: 1px solid #445161;
        color: $white;
        letter-spacing: rem-calc(1);
        padding-block-start: rem-calc(18);
        padding-inline-start: rem-calc(30);
        text-decoration: none;

        &:hover {
          @include body7-font;
          color: $white;
        }

        &.js-hide {
          display: none;
        }
      }

      .toggle-mobile-subnav {
        @include body7-font;
      }
    }

    .menu.nested {
      background: #38485c;
      inline-size: 100%;
      margin-inline-start: 0;
      position: absolute;
    }

    .is-submenu-item {
      margin: 0;

      a {
        @include body8-font;
        //                padding-left: rem-calc(35);
        padding-block-start: rem-calc(18);
      }
    }

    .toggle-mobile-subnav {
      &::after {
        content: url("/assets/images/menuDropdownArrow.png");
        display: block;
        inset-block-start: 18px;
        inset-inline-end: 4%;
        position: absolute;
      }
    }

    .is-accordion-submenu-parent[aria-expanded="true"] {
      .toggle-mobile-subnav {
        &::after {
          content: url("/assets/images/menuDropdownArrow_up.png");
          display: block;
          inset-block-start: 18px;
          inset-inline-end: 4%;
          position: absolute;
        }
      }
    }
  }
}

.apply-mobile {
  background: $primary-color;
  block-size: 40px;
  color: $white;
  inline-size: 100%;
  position: fixed;
  z-index: 500;

  a {
    color: $white;
  }
}
