//Typography

body {
  font-family: var(--primary-font);
  font-size: 18px;
  line-height: 1.78;
}

// ** FONT WEIGHTS & STYLES ** //

/* Text meant only for screen readers. */
.screen-reader-text {
  block-size: 1px;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  inline-size: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  word-wrap: normal !important;
}

.dark {
  a {
    color: #fff;

    &:hover {
      color: #a7abb3;
      text-decoration: none;
    }
  }
}

// a {
//   &:hover {
//     color: #a7abb3;
//     text-decoration: none;
//   }
// }

p {
  font-size: rem-calc(14);
  @include breakpoint(small only) {
    font-size: rem-calc(14);
    line-height: rem-calc(28);
  }
  @include breakpoint(medium only) {
    font-size: rem-calc(14);
    line-height: rem-calc(24);
  }

  a {
    text-decoration: underline;
  }
}

.white p.strong a {
  color: inherit;

  &:hover {
    opacity: 0.7;
  }
}

.insights.white p.strong a {
  color: $dark-blue;

  &:hover {
    opacity: 0.7;
  }
}

.white li a {
  text-decoration: underline;

  &:hover {
    opacity: 0.7;
  }
}

.white p a {
  color: $dark-blue;
}

p.two-column {
  column-count: 2;
  column-gap: 20px;
  @include breakpoint(small only) {
    column-count: 1;
    column-gap: 0;
  }
}

.legal-sub {
  font-size: rem-calc(12);
  inline-size: 70%;
  margin-block-start: rem-calc(5);
  opacity: 0.5;
}

p.legal-sub-subcribe {
  font-size: rem-calc(12);
  font-style: italic;
  margin-block-start: rem-calc(70);
  opacity: 0.5;

  a {
    color: $white;

    &:hover {
      opacity: 0.5;
    }
  }
}

p.legal-sub-academy {
  font-size: rem-calc(12);
  font-style: italic;
  opacity: 0.5;
}

h4.contact-name {
  font-size: rem-calc(18);
  margin-block-end: rem-calc(5);
  @include breakpoint(small only) {
    font-size: rem-calc(16);
  }
}

.error-text {
  display: block;
  margin-block-end: 20%;
}

footnote {
  color: $black;
  display: block;
  font-size: rem-calc(11);
  line-height: 2;
  margin: rem-calc(50) 0;

  sup {
    font-size: rem-calc(9);
    inset-block-start: -0.5rem;
  }

  p {
    color: $black;
    font-size: rem-calc(11);
  }

  ul {
    li {
      font-size: rem-calc(11);
      line-height: 1;
    }
  }
}

.callout-section {
  border-inline-start: 4px solid #f8e71c;
  display: block;
  margin-block-end: rem-calc(45);
  padding-inline-start: rem-calc(25);
  @include breakpoint(medium down) {
    p {
      font-size: rem-calc(18);
    }
  }

  p {
    font-size: rem-calc(22);
  }
}

.infograph-detail {
  display: inline-block;
  font-size: rem-calc(14);
  font-style: italic;
  margin-block-start: rem-calc(190);
}

span.here-only-for-aoda {
  block-size: 1px;
  inline-size: 1px;
  inset-block-start: -1000px;
  overflow: hidden;
  position: absolute;
}

// FONT IMPORT - Neue Haas
@font-face {
  font-family: "NHaasGroteskTXStd-55Rg";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/NHaasGroteskTXStd-55Rg.woff2") format("woff2"),
    url("/assets/fonts/NHaasGroteskTXStd-55Rg.woff") format("woff");
}

@font-face {
  font-family: "NHaasGroteskDSStd-35XLt";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/NHaasGroteskDSStd-35XLt.woff2") format("woff2"),
    url("/assets/fonts/NHaasGroteskDSStd-35XLt.woff") format("woff");
}

@font-face {
  font-family: "NHaasGroteskDSStd-45Lt";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/NHaasGroteskDSStd-45Lt.woff2") format("woff2"),
    url("/assets/fonts/NHaasGroteskDSStd-45Lt.woff") format("woff");
}

@font-face {
  font-family: "NHaasGroteskDSStd-46LtIt";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/NHaasGroteskDSStd-46LtIt.woff2") format("woff2"),
    url("/assets/fonts/NHaasGroteskDSStd-46LtIt.woff") format("woff");
}

@font-face {
  font-family: "NHaasGroteskDSStd-55Rg";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/NHaasGroteskDSStd-55Rg.woff2") format("woff2"),
    url("/assets/fonts/NHaasGroteskDSStd-55Rg.woff") format("woff");
}

@font-face {
  font-family: "NHaasGroteskDSStd-65Md";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/NHaasGroteskDSStd-65Md.woff2") format("woff2"),
    url("/assets/fonts/NHaasGroteskDSStd-65Md.woff") format("woff");
}

@mixin menu-font {
  font-family: "NHaasGroteskDSStd-55Rg", Helvetica, Arial, sans-serif;
  font-size: rem-calc(16);
  letter-spacing: 1.4px;
  // text-transform: uppercase;
}

@mixin footer-font {
  font-family: "NHaasGroteskDSStd-55Rg", Helvetica, Arial, sans-serif;
  font-size: rem-calc(11);
  letter-spacing: 0;
}

@mixin displayXXL-font {
  font-family: "NHaasGroteskDSStd-35XLt", Helvetica, Arial, sans-serif;
  font-size: rem-calc(96);
  letter-spacing: 2;
  @include breakpoint(large only) {
    font-size: 6vw;
  }
  @include breakpoint(medium down) {
    font-size: 10vw;
  }
  @media screen and (max-height: 680px) and (orientation: portrait) {
    margin-block-start: rem-calc(-75);
  }
  @media only screen and (max-height: 660px) {
    font-size: 4.8vw;
  }
  @include breakpoint(small down) {
    font-size: 9vw;
    margin-block-start: rem-calc(0);
  }
}

@mixin displayXL-font {
  font-family: "NHaasGroteskDSStd-35XLt", Helvetica, Arial, sans-serif;
  font-size: rem-calc(54);
  letter-spacing: 1px;
  @include breakpoint(small down) {
    font-size: rem-calc(44);
    letter-spacing: 1;
  }
}

@mixin displayL-font {
  font-family: "NHaasGroteskDSStd-35XLt", Helvetica, Arial, sans-serif;
  font-size: rem-calc(36);
  letter-spacing: 1.8px;
  @include breakpoint(small down) {
    font-size: rem-calc(24);
    letter-spacing: 1px;
  }
}

@mixin displayM-font {
  font-family: "NHaasGroteskDSStd-45Lt", Helvetica, Arial, sans-serif;
  font-size: rem-calc(36);
  letter-spacing: 0.4px;
  @include breakpoint(small down) {
    font-size: rem-calc(28);
    letter-spacing: 1.8px;
  }
}

@mixin displayS-font {
  font-family: "NHaasGroteskDSStd-45Lt", Helvetica, Arial, sans-serif;
  font-size: rem-calc(32);
  letter-spacing: 0.4px;
  @include breakpoint(small down) {
    font-size: rem-calc(24);
  }
}

@mixin displayXS-font {
  font-family: "NHaasGroteskDSStd-45Lt", Helvetica, Arial, sans-serif;
  font-size: rem-calc(28);
  letter-spacing: 0.4px;
  @include breakpoint(small down) {
    font-size: rem-calc(22);
  }
}

@mixin displayXXS-font {
  font-family: "NHaasGroteskDSStd-45Lt", Helvetica, Arial, sans-serif;
  font-size: rem-calc(26);
  letter-spacing: 0.4px;
  @include breakpoint(small down) {
    font-size: rem-calc(18);
  }
}

@mixin data1-font {
  font-family: "NHaasGroteskDSStd-46LtIt", Helvetica, Arial, sans-serif;
  font-size: rem-calc(72);
  letter-spacing: 5px;
  @include breakpoint(small down) {
    font-size: rem-calc(42);
  }
}

@mixin data2-font {
  font-family: "NHaasGroteskDSStd-45Lt", Helvetica, Arial, sans-serif;
  font-size: rem-calc(72);
  letter-spacing: 1px;
  @include breakpoint(small down) {
    font-size: rem-calc(42);
  }
}

@mixin body1-font {
  font-family: "NHaasGroteskDSStd-45Lt", Helvetica, Arial, sans-serif;
  font-size: rem-calc(22);
  letter-spacing: 0.4px;
  @include breakpoint(small down) {
    font-size: rem-calc(16);
    letter-spacing: 0.4px;
  }
}

@mixin body1-font-serif {
  font-family: Georgia, Times, "Times New Roman", serif;
  font-size: rem-calc(22);
  letter-spacing: 0;
  @include breakpoint(small down) {
    font-size: rem-calc(16);
    letter-spacing: 0.4px;
  }
}

@mixin body2-font {
  font-family: "NHaasGroteskDSStd-45Lt", Helvetica, Arial, sans-serif;
  font-size: rem-calc(16);
  letter-spacing: 0.4px;
  line-height: 2;
  @include breakpoint(small down) {
    font-size: rem-calc(16);
    letter-spacing: 0.4px;
    line-height: 2;
  }
}

@mixin body3-font {
  font-family: "NHaasGroteskDSStd-55Rg", Helvetica, Arial, sans-serif;
  font-size: rem-calc(14);
  letter-spacing: 0.6px;
  line-height: 1.6;
  @include breakpoint(small down) {
    font-size: rem-calc(14);
    letter-spacing: 0.6px;
    line-height: 1.6;
  }
}

@mixin body4-font {
  font-family: "NHaasGroteskDSStd-55Rg", Helvetica, Arial, sans-serif;
  font-size: rem-calc(18);
  letter-spacing: 0.4px;
  line-height: 1.6;
  @include breakpoint(small down) {
    font-size: rem-calc(14);
    letter-spacing: 0.4px;
    line-height: 1.6;
  }
}

@mixin body5-font {
  font-family: "NHaasGroteskDSStd-65Md", Helvetica, Arial, sans-serif;
  font-size: rem-calc(18);
  letter-spacing: 1.8px;
  line-height: 2;
  text-transform: uppercase;
  @include breakpoint(small down) {
    font-size: rem-calc(15);
    line-height: 2;
  }
}

@mixin body6-font {
  font-family: "NHaasGroteskDSStd-65Md", Helvetica, Arial, sans-serif;
  font-size: rem-calc(16);
  letter-spacing: 1.4px;
  @include breakpoint(small down) {
    font-size: rem-calc(14);
    letter-spacing: 0.8px;
    line-height: 1.2;
  }
}

@mixin body7-font {
  font-family: "NHaasGroteskDSStd-65Md", Helvetica, Arial, sans-serif;
  font-size: rem-calc(14);
  letter-spacing: 0.4px;
  line-height: 1.6;
}

@mixin body8-font {
  font-family: "NHaasGroteskDSStd-55Rg", Helvetica, Arial, sans-serif;
  font-size: rem-calc(14);
  letter-spacing: 0.4px;
  line-height: 1.6;
}

@mixin body9-font {
  font-family: "NHaasGroteskDSStd-65Md", Helvetica, Arial, sans-serif;
  font-size: rem-calc(12);
  letter-spacing: 1px;
}

@mixin body10-font {
  font-family: "NHaasGroteskDSStd-55Rg", Helvetica, Arial, sans-serif;
  font-size: rem-calc(12);
  letter-spacing: 0.6px;
  line-height: 1.8;
}

@mixin body-font {
  font-family: Georgia, Times, "Times New Roman", serif;
  font-size: rem-calc(14);
  letter-spacing: 0.2px;
  line-height: rem-calc(22);
}

p.lead-strong {
  @include displayXXS-font;
  font-weight: bold;
  margin-block-end: rem-calc(70);
  @include breakpoint(small only) {
    margin-block-end: rem-calc(35);
  }
}

.strong {
  @include body4-font;
  margin-block-end: 5px;

  a {
    color: $white;
    text-decoration: none;

    &:hover {
      color: #a7abb3;
      text-decoration: none;
    }
  }
}

.lead {
  @include body1-font;
  display: block;
  font-style: italic;
  line-height: 1.4;

  .link-white {
    @include body1-font;
  }

  .tel-link {
    @include body1-font;
    // text-decoration: none;
  }

  a:hover {
    opacity: 0.7;
  }
}

.lead-reg {
  @include body1-font;
  display: block;
  font-style: normal;
  line-height: 1.5;

  .link-white {
    @include body1-font;
  }

  .tel-link {
    @include body1-font;
    // text-decoration: none;
  }
}

.lead-serif {
  @include body1-font-serif;
  display: block;
  font-style: italic;
  line-height: 1.4;

  .link-white {
    @include body1-font;
  }

  .tel-link {
    @include body1-font;
    // text-decoration: none;
  }
}

.lead-alt {
  font-style: normal;
}

.strong-subtitle {
  @include body7-font;
  margin-block-end: rem-calc(3);
}

.link-white {
  @include body3-font;
  color: $white;
  text-decoration: underline;

  &:hover {
    color: $grey-hover;
  }

  &:focus,
  &:active {
    color: $white;
  }
}

.link-white-alt {
  @include body8-font;
  color: $white;
  display: block;
  text-decoration: underline;

  &:hover {
    color: $grey-hover;
  }
}

.link-white-plain {
  color: $white;
  text-decoration: none;

  &:hover {
    color: $grey-hover;
  }
}

.link-white-plain-underline {
  color: $white !important;
  text-decoration: underline !important;

  &:active,
  &:visited,
  &:focus {
    color: $white;
  }

  &:hover {
    color: $grey-hover !important;
    text-decoration: none !important;
  }
}

.link-blue {
  @include body3-font;
  color: $primary-color;
  text-decoration: underline;

  &:hover {
    color: $primary-color;
    text-decoration: none;
  }
}

h1,
.as-h1 {
  @include displayXL-font;
}

h2,
.as-h2 {
  @include displayL-font;
}

h3,
.as-h3 {
  @include displayM-font;
}

h4,
.as-h4 {
  @include displayXS-font;
}

h6,
.as-h6 {
  @include body6-font;
}

p {
  @include body-font;
}

ul li {
  @include body-font;
}

ol li {
  @include body-font;
}

h2.latest {
  @include displayXL-font;
}

/* added to make all links a 'hand' rather than the foundation default I-bar - 2017.12.15 (MK) */
a:any-link {
  cursor: pointer !important;
}

/* some VI-update classes added here but styled like the old site, so we can make markup changes now without affecting the current site */

// @mixin body1-font-serif {
//   font-family: Georgia, Times, "Times New Roman", serif;
//   font-size: rem-calc(22);
//   letter-spacing: 0;
//   @include breakpoint (small down) {
//     font-size: rem-calc(16);
//     letter-spacing: 0;
//   }
// }
// .lead-reg {
//   display: block;
//   @include body1-font-serif;
//   font-style: italic;
//   line-height: 36px;
// 	.link-white {
// 		@include body1-font-serif;
// 	}
// 	.tel-link {
// 		@include body1-font-serif;
// 		text-decoration: none;
// 	}
// }
// .lead-serif {
//   display: block;
//   @include body1-font-serif;
//   font-style: italic;
//   line-height: 36px;
// 	.link-white {
// 		@include body1-font-serif;
// 	}
// 	.tel-link {
// 		@include body1-font-serif;
// 		text-decoration: none;
// 	}
// }
