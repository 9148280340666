.gated-modal {
  background-color: rgba(30, 31, 33, 0.6);
  block-size: auto;
  inline-size: 100%;
  inset-block: 0;
  inset-inline: 0;
  position: absolute;
  z-index: 99999;
  padding: 0;
  border-color: transparent;

  .strong {
    font-family: NHaasGroteskDSStd-55Rg, Helvetica, Arial, sans-serif;
    font-size: 1.125rem;
    letter-spacing: 0.4px;
    line-height: 1.6;
    margin-block-end: 5px;
  }

  .white p a {
    color: $black;
  }

  a:not(.button, .seconadary) {
    color: $black;
    text-decoration: underline;
  }

  a:hover {
    opacity: 1;
    text-decoration: none;
  }
}

.gated-modal-container {
  background-color: #fff;
  inline-size: 100%;
  block-size: 100%;
  padding: 40px 24px;
  display: flex;
  flex-flow: column;
  align-items: center;
  position: relative;
  max-block-size: 100vh;

  @media all and (min-width: 768px) {
    inline-size: 70%;
    block-size: auto;
    max-block-size: 80%;
    margin: 10vh auto;
    padding: 40px;
    align-items: flex-start;
  }

  .button {
    padding-inline: 24px;
    padding-block-start: 16px;
    margin: 0 auto;

    @media all and (min-width: 768px) {
      padding-inline: 0;
      margin: unset;
    }
  }

  svg {
    fill: $black;
    inline-size: 45px;
    block-size: 45px;
    padding: 10px;
  }
}

.gated-modal-content {
  padding: 0;
  overflow: auto;
  p,
  h1 {
    color: $black !important;
  }

  p {
    
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.2px;
  }

  .gated-modal__title {
    font-family: "NHaasGroteskDSStd-45Lt", Helvetica, Arial, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 450;
    line-height: 38px;
    letter-spacing: 1px;
  }

  h5 {
    font-family: Neue Haas Grotesk Display Pro;
    font-size: 18px;
    font-style: normal;
    font-weight: 450;
    line-height: 20px;
  }

  ul:not(.tabs,.tabs-title,.is-submenu-item,.link-list,.link-list-dark) li{
    list-style-image: url("/assets/images/bullet.png");
    color: $black;
  }

  ol:not(.tabs,.tabs-title,.is-submenu-item,.link-list,.link-list-dark) li{
    color: $black;
  }
}

.gated-modal-container-inner {
  position: relative;
}

.gated-modal-close {
  inset-block-start: 0;
  inset-inline-end: 0;
  opacity: 1;
  padding: 0.25em;
  position: absolute;
  z-index: 2;
}
