//fix for inside accordion sub-menu anchor color
.slide-container .slide-content.right-side {
  .nested.submenu.is-accordion-submenu {
    .is-submenu-item a,
    .tabs-title a {
      color: #fefefe;
    }
  }
}

.text-dropdown,
.text-dropdown-white {
  [aria-expanded="true"] + .submenu {
    display: block;
  }

  .submenu {
    display: none;
    height: 0;
    overflow: hidden;
    transition: all 0.5s ease-in-out;

    a {
      font-family: inherit; // for the <a>s in the content of the accordions on the student/city page
      text-decoration: underline;

      &:hover {
        text-decoration: none !important;
      }
    }
  }
}

.text-dropdown {
  margin: 0 0 10px;

  li {
    &:first-child {
      margin-block-start: 10px;
    }

    a,
    button {
      @include body8-font;
      color: $white;
      line-height: 1.3;
      padding: 0;
    }
  }

  .toggle-title {
    @include body7-font;
    align-items: center;
    color: rgba($white, 0.7);
    display: flex;
    inline-size: 100%;
    justify-content: flex-start;
    position: relative;
    text-decoration: none;
    transition: color 200ms ease;

    .js-highlight-tabs &,
    &:hover {
      color: $white;
    }

    &::after {
      color: $secondary-color;
      content: "+";
      font-size: rem-calc(16);
      inset-inline-start: 100%;
      margin-block-start: 0;
      margin-inline-start: 5px;
    }
  }

  .toggle-button[aria-expanded="true"] {
    @include body7-font;
    color: $white !important;

    &::after {
      @include body6-font;
      color: $secondary-color;
      content: "-";
      inset-inline-start: 100%;
      margin-block-start: 0;
    }
  }
}

.text-dropdown-white {
  margin: 0 0 20px;
  padding: 0;
  position: relative;

  ul {
    margin: 10px 0 0;
  }

  li {
    a,
    button {
      @include body8-font;
      color: $black !important;
      margin-block-end: 5px;
      padding: 0;
      text-decoration: none;
    }
  }

  .toggle-title {
    color: rgba($primary-color, 0.7);
    transition: color 200ms ease;

    &:hover,
    &:focus {
      color: $white;
    }

    &::after {
      color: $primary-color;
      content: "+";
      font-size: rem-calc(16);
      margin-inline-start: 5px;
      position: absolute;
    }
  }

  .toggle-title[aria-expanded="true"] {
    @include body7-font;
    color: $primary-color !important;

    &::after {
      color: $primary-color;
      content: "-";
      font-size: rem-calc(16);
      margin-left: 5px;
      margin-top: -2px;
      position: absolute;
    }
  }
}

a.no-expand-link,
button.no-expand-link {
  @include body8-font;
  color: rgba($white, 0.7);
  display: block;
  line-height: 1.3;
  margin-block: rem-calc(-3) rem-calc(20);
  text-align: start;
  transition: color 200ms ease;

  .js-highlight-tabs &,
  &:hover {
    color: $white;
  }
}

.detail-share {
  margin: 25px 0 0;
  @include breakpoint(small down) {
    margin: 25px 0 0;
  }

  li {
    @include body3-font;
    border-block-end: 0 !important;
    display: inline-block;
    list-style: none;
    padding-inline-end: rem-calc(50);

    img,
    svg {
      font-size: inherit;
      margin-inline-end: 0.7em;
    }
  }

  .bio-download {
    text-decoration: none;

    img,
    svg {
      vertical-align: middle;
    }
  }
}
