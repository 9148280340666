.student-profile {
  backface-visibility: hidden;
  block-size: 150px;
  display: inline-block;
  float: inline-start;
  inline-size: 150px;
  margin-block-end: 40px;
  margin-inline-end: rem-calc(15);

  a:hover {
    opacity: 0.5;
  }
  @media (max-width: 1334px) {
    margin-block-end: 0;
  }
  @media (max-width:1024px) {
    margin-block-end: 40px;
  }

  .students-city-applying & {
    @media (max-width: 950px) {
      margin-block-end: 0;
    }
  }
  @media (max-width: 794px) {
    margin-block-end: 0;
  }

  img {
    border-radius: 150px;
  }
  @media (max-width:330px) {
    block-size: 150px;
    inline-size: 150px;
    margin-inline-end: rem-calc(10);
  }
  @media (min-width: 1024px) and (max-width: 1080px) {
    block-size: 150px;
    inline-size: 150px;
    margin-inline-end: rem-calc(10);
  }
}
// .student-profile a:hover {
// 	opacity: 0.5;
// }

.student-info {
  display: inline-block;
  inline-size: 50%;
  margin-block: 20px rem-calc(60);
  padding-block-start: rem-calc(15);
  word-break: break-all;
  word-break: break-word;
  @media (max-width: 1334px) {
    inline-size: 100%;
    margin-block-start: 0;
  }
  @media (max-width:1024px) {
    inline-size: 50%;
    margin-block-start: 20px;
  }

  .students-city-applying & {
    @media (max-width: 950px) and (min-width: 640px) {
      inline-size: 100%;
      margin-block-start: 0;
    }
  }
  @media (max-width: 794px) {
    inline-size: 100%;
    margin-block-start: 0;
  }
  @media (max-width: 640px) {
    inline-size: 50%;
    margin-block-start: 20px;
  }
  @media (max-width: 402px) {
    inline-size: 100%;
    margin-block-start: 0;
  }

  p.strong {
    @include body7-font;
    font-style: normal;
    line-height: 1.5;
    margin-block-end: 0;
  }

  p {
    @include body3-font;
    margin-block-end: 0;
    @media (min-width: 1024px) and (max-width: 1080px) {
      font-size: rem-calc(12);
    }
    @media (min-width: 640px) and (max-width: 680px) {
      font-size: rem-calc(12);
    }
  }

  a {
    text-decoration: none;
  }

  a p.strong:hover {
    opacity: 0.5;
  }
}

.class-heading {
  margin-block-end: rem-calc(30);
}

.round-profile {
  border-radius: 300px;
  @include breakpoint (medium down) {
    max-inline-size: 200px;
  }
}
