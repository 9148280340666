* {
  word-wrap: break-word;
}

.js-overflow-hidden {
  overflow: hidden !important;
}

[data-accordion-menu] {
  display: none;
}

[data-accordion-menu$="accordion-menu"] {
  display: block;
}

::selection {
  background: rgba($medium-gray, 0.66);
  color: $primary-color;
}

::selection {
  background: rgba($medium-gray, 0.66);
  color: $primary-color;
}

html,
body {
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
}

a:not(.button, .seconadary) {
  color: $primary-color;
  text-decoration: underline;
}

a:hover {
  color: $primary-color;
  opacity: 1;
  text-decoration: none;
}

[hidden] {
  block-size: 0;
  visibility: hidden;
}

[data-drawer] {
  * {
    pointer-events: none;
  }
}

.main-content {
  margin-block-start: 50px;
}

.article-lazyload {
  height: 0;
  opacity: 0;
  transition: all 0.4s ease-in;
  visibility: hidden;
}

.article-lazyload--revealed {
  height: auto;
  opacity: 1;
  visibility: visible;
}
