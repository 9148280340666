.home-hero__slide {
  display: none;
  flex-flow: column;
  inline-size: 100%;

  &:first-child {
    display: flex;
  }
}

.home-hero__subtext {
  @include body5-font;

  align-self: flex-start;
  display: inline-block;
}

.home-hero__text {
  @include displayXXL-font;
  align-items: flex-start;
  display: flex;
  flex-flow: column;
  margin-block-end: rem-calc(20);
}

.home-hero__animation {
  overflow: visible;
}

.home-hero__line {
  position: relative;
  white-space: nowrap;
}

.home-hero__underline {
  $hardware-acceleration: true;
  text-rendering: optimizeLegibility;
  transition-delay: 10ms;
  transition-duration: 100ms;
  transition-property: none;

  @if ($hardware-acceleration) {
    backface-visibility: hidden;
    perspective: 1000px;
  }

  &::after {
    background-color: $secondary-color;
    block-size: 3px;
    content: "";
    display: flex;
    inline-size: 100%;
    inset-block-end: 0;
    inset-inline-end: 0;
    outline: 1px solid rgba(0, 0, 0, 0);
    position: absolute;
    transform: scaleX(0);
    transform-origin: left bottom;
    transition-delay: inherit;
    transition-duration: inherit;
    transition-property: transform;
    transition-timing-function: ease-out;
    @if ($hardware-acceleration) {
      transform-style: preserve-3d;
    }
  }

  &.js-animating-in::after {
    transform: scaleX(1);
  }

  &.js-animating-out::after {
    transform-origin: right bottom;
  }
}

.home-hero__cta {
  @include body6-font;
  align-items: center;
  color: $white;
  display: inline-flex;
  position: relative;
  text-decoration: none !important;
  text-transform: uppercase;

  @include breakpoint(small down) {
    font-size: rem-calc(11);
  }

  &::before {
    color: $secondary-color;
    content: "\f111";
    font-family: FontAwesome;
    font-size: rem-calc(10);
    margin-inline-end: 1.5em;
  }

  &:hover {
    color: #fff;

    &::before {
      color: #fff;
    }
  }
}

.home-hero__controls {
  $hero-controls-font-size: rem-calc(14);
  align-items: center;
  display: flex;
  margin-block-start: 1em;
  position: relative;
  user-select: none;

  a {
    color: $white;
    display: inline-flex;
    font-family: "NHaasGroteskDSStd-45Lt", Helvetica, Arial, sans-serif;
    font-size: 0;
    position: relative;
    text-decoration: none;
    vertical-align: middle;

    &::before,
    &::after {
      content: "";
    }
  }

  .home-hero__indicator {
    font-size: $hero-controls-font-size;
    margin: 0 0.65em;
  }

  .home-hero__previous,
  .home-hero__next {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    block-size: $hero-controls-font-size * 1.1;
    inline-size: $hero-controls-font-size * 1.1;
  }

  .home-hero__previous {
    background-image: url("/assets/images/fa-angle-left.svg");
  }

  .home-hero__next {
    background-image: url("/assets/images/fa-angle-right.svg");
  }

  .home-hero__pause {
    block-size: $hero-controls-font-size;
    inline-size: $hero-controls-font-size;
    margin-inline-start: 0.8rem;

    &::before,
    &::after {
      background-color: $white;
      block-size: $hero-controls-font-size * 0.7;
      display: flex;
      inline-size: 2px;
      inset-block-start: $hero-controls-font-size * 0.15;
      inset-inline-start: 0.4rem;
      position: absolute;
    }

    &::before {
      margin-inline-start: -3px;
    }

    &::after {
      margin-inline-start: 1px;
    }

    &.js-paused {
      &::before {
        background-color: transparent;
        block-size: 0;
        border-color: transparent transparent transparent $white;
        border-style: solid;
        border-width: 0.25rem 0 0.25rem 0.5rem;
        inline-size: 0;
        inset-block-start: 0.2rem;
        inset-inline-start: 0.2rem;
        margin-inline-start: 0;
      }

      &::after {
        display: none;
      }
    }
  }

  .home-hero__trainstop::before {
    content: "●";
  }
}

.search-block {
  margin-block-start: 7.5%;
  position: relative;
  width: 100%;

  @media only screen and (min-height: 801px) {
    margin-block-start: rem-calc(110);
  }
  @include breakpoint(small down) {
    margin-block: rem-calc(30);
  }
}

.home-page .main-footer {
  @include breakpoint(large up) {
    inset-inline-start: 0;
    padding-inline: 7.5% 345px;
    position: absolute;

    .footer-menu {
      margin-inline-end: 0;
    }
  }
}
