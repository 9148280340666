#bio-headshot {
  background: url("/assets/images/ProfileBioBackground.jpg") no-repeat;
  background-size: cover;
  block-size: auto;
  display: block;
  inline-size: 100%;
  position: relative;
  position: relative;
  @media all and (max-width: 640px) {
    block-size: 300px;
    overflow: hidden;
  }
  @media all and (max-width: 460px) {
    block-size: 250px;
    overflow: hidden;
  }

  .bio-img {
    @media all and (max-width: 1420px) {
      margin: 0 40px;
    }
    @media all and (max-width: 1140px) {
      margin: 0 20px;
    }
  }

  .large-4.medium-4.text-center.column {
    flex: 0 0 50%;
    max-inline-size: 50%;
  }

  img {
    margin-inline-end: -30px;
    block-size: 100%;
    max-block-size: 660px;
    padding-block-start: 70px;
    margin-block-start: 10px;
    object-fit: contain;
    @media all and (max-width: 1280px) {
      max-block-size: 600px;
    }
    @media all and (max-width: 1024px) {
      margin-inline-end: 0;
    }
    @media all and (max-width: 639px) {
      block-size: 380px;
      float: inline-end;
    }
    @media all and (max-width: 660px) {
      padding-block-start: 90px;
    }
    @media all and (max-width: 640px) {
      padding-block-start: 60px;
    }
    @media all and (max-width: 460px) {
      block-size: 300px;
      float: inline-end;
      padding-inline-end: 60px;
      min-inline-size: 270px;
      inset-inline-end: 50px;
      inset-block-end: 20px;
      position: relative;
    }
  }
}

.bio-name-lockup {
  display: block;
  inline-size: 60%;
  max-inline-size: rem-calc(850);
  padding: 100px 0 0 12%;
  position: absolute;
  word-break: normal;
  @include breakpoint(small only) {
    inline-size: 100%;
    margin-block: rem-calc(20) rem-calc(40);
    padding: 0;
    position: inherit;
  }

  p {
    @include body1-font;
    color: $primary-color;
    margin-inline-start: 0;
  }

  h2 {
    color: $primary-color;
    display: inline-block;
    line-height: 1.3;
    margin-block-end: 5px;
    margin-inline-start: 10px;
    @include breakpoint(small only) {
      margin-inline-start: 0;
    }
  }
}

.bio-name-lockup-student {
  @include breakpoint(small only) {
    margin-block: rem-calc(20) rem-calc(40);
    padding: 0;
    position: inherit;
  }

  p {
    @include body1-font;
    color: $primary-color;
    margin-block-start: rem-calc(8);
    margin-inline-start: 0;
  }

  h1 {
    color: $primary-color;
    display: inline-block;
    line-height: 1.3;
    margin-block-end: 5px;
  }
}

.bio-name-lockup-details {
  margin-block-end: 0;
  padding-block-start: rem-calc(30);
  @include breakpoint(small only) {
    margin-block-end: rem-calc(60);
    padding-block-start: 0;
  }

  p {
    @include body1-font;
    color: $primary-color;
  }

  h1 {
    color: $primary-color;
    display: inline-block;
    line-height: 1.3;
    margin-block-end: 10px;
  }
}

#bio-details {
  display: block;
  position: relative;
  height: 100%;
  padding: 5% 12%;
  h5 {
    font-size: rem-calc(14);
    margin-bottom: rem-calc(5);
    @include body7-font;
    color: $black !important;
  }
  .bio-list {
    margin: 0 0 40px 0;
    li {
      list-style: none;
      font-size: rem-calc(14);
      color: $black !important;
      @include body3-font;
      a {
        text-decoration: underline;
        color: $black !important;
      }
      a:hover {
        color: #a7abb3 !important;
        text-decoration: none;
      }
      @media all and (max-width: 640px) {
        display: block;
      }
    }
    .email-link {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    .phone-link {
      text-decoration: underline;
      color: $black;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .bio-download {
    display: block;
    margin-bottom: 10px;
    text-decoration: none;

    @include body3-font;
    &:before {
      content: url(/assets/images/DownloadArrow.png);
      display: inline-block;
      margin-right: 5px;
    }
    @media all and (max-width: 640px) {
      display: block;
      margin-bottom: 0;
      float: right;
      width: 51%;
      text-align: right;
    }
    @media all and (max-width: 325px) {
      font-size: rem-calc(12);
    }

    &:hover {
      color: #a7abb3;
      text-decoration: none;
    }
  }
  .social-lockup {
    margin: 40px 0;
    display: block;

    ul {
      margin: 0;
      @media all and (max-width: 640px) {
        display: inline-block;
        margin: 0;
      }
    }
    li {
      display: inline-block;
      list-style: none;
      padding-right: 15px;
      @media all and (max-width: 325px) {
        padding-right: 5px;
      }
    }
    @media all and (max-width: 640px) {
      margin: 0 0 40px 0;
      padding: 15px 0 38px 0;
      border-top: 1px solid #ebebeb;
      border-bottom: 1px solid #ebebeb;
      width: 100%;
    }
    @media all and (max-width: 375px) {
      margin: 0 0 40px 0;
      padding: 15px 0 38px 0;
      border-top: 1px solid #ebebeb;
      border-bottom: 1px solid #ebebeb;
      width: 100%;
    }
    .dropdown-pane {
      background: #2d3a4a;
      border-top: 10px solid $white;
      border-left: none;
      border-right: none;
      border-bottom: none;
      outline: none;
      padding: 6px 10px 10px 10px;
      width: fit-content;
      height: auto;
      display: flex;
      align-items: center;

      &:before {
        font-family: FontAwesome;
        content: "\f0d8";
        position: absolute;
        color: #2d3a4a;
        font-size: 1rem;
        top: -15px;
        left: 4px;
      }
      .social-list {
        align-items: center;
        justify-content: center;
        display: flex;
        gap: 20px;
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          display: flex;
          padding: 0;
          align-items: center;
          justify-content: space-between;

          a {
            @include body10-font;

            &:hover {
              text-decoration: underline;
            }
            img,
            svg {
              inline-size: 23px;
              block-size: 100%;
            }
          }
          &:last-child {
            padding-right: 0;
          }
        }
        .social-list-icon {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }
}

#bio-overview {
  display: block;
  position: relative;

  .bio-contact {
    float: inline-end;
    margin-block-start: 440px;
    @include breakpoint(small down) {
      float: none;
      margin-block: rem-calc(20) rem-calc(40);
    }
  }

  article {
    p.strong {
      color: $primary-color !important; /* bio-rep work-titles - 2018.02.27 (MK) */
      font-family: "NHaasGroteskDSStd-65Md", Helvetica, Arial, sans-serif;
      font-size: rem-calc(16);
      margin-block-end: rem-calc(5);

      a {
        color: $primary-color;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  .recognition {
    margin-block-end: 20px;

    p {
      margin-block-end: 0;
    }
  }

  .education {
    margin-block-end: rem-calc(30);
  }

  #insights {
    .button {
      margin: 20px 0 0;
    }

    .top-space:not(:first-child) {
      margin: 80px 0 0;
    }

    .strong {
      font-family: "NHaasGroteskDSStd-55Rg", Helvetica, Arial, sans-serif;
      font-weight: bold;
    }
  }

  .tabs {
    background: none;
    border: none;
    margin-block-start: 30px;

    li {
      @include body8-font;
      padding: 0 50px 40px 0;
      @media all and (max-width: 1280px) {
        padding: 0 40px 40px 0;
      }
      @media all and (max-width: 1225px) {
        padding: 0 30px 40px 0;
      }
      @media all and (max-width: 1170px) {
        padding: 0 18px 40px 0;
      }
      @media all and (max-width: 1023px) {
        padding: 0 50px 40px 0;
      }
      @media all and (max-width: 935px) {
        padding: 0 40px 40px 0;
      }
      @media all and (max-width: 880px) {
        padding: 0 30px 40px 0;
      }
      @media all and (max-width: 825px) {
        padding: 0 20px 40px 0;
      }

      &:last-child {
        padding: 0 0 40px;
      }

      a {
        color: $medium-blue;
        font-size: rem-calc(14);
        margin: 0;
        padding: 0;
        text-decoration: none;

        &:focus,
        &:hover {
          background: none;
        }
        @media all and (max-width: 725px) {
          font-size: rem-calc(12);
        }
        @media all and (max-width: 1170px) {
          font-size: rem-calc(12);
        }
        @media all and (max-width: 1023px) {
          font-size: rem-calc(14);
        }
      }

      &.is-active a {
        @include body7-font;
        background: none;
        color: $dark-blue;
        line-height: 1.5;
      }
    }

    .tabs-title > a[aria-selected="true"] {
      @include body7-font;
      background: none;
      line-height: 1.5;
    }

    .tabs-title > a {
      display: initial; /* 2018.02.01 (MK) */
    }
  }
}

.student-bio-intro {
  img {
    @include breakpoint(small down) {
      margin-block-end: 40px;
    }
  }

  .social-links {
    a {
      display: inline-block;
      margin-inline-end: 20px;
    }
  }
}

.student-bio-contact {
  @include body3-font;
  border: 1px solid $light-gray;
  border-inline-end: none;
  border-inline-start: none;
  color: $black;
  display: block;
  font-size: rem-calc(14);
  margin: 40px 0;
  padding: 15px 0 20px;
  @include breakpoint(medium down) {
    margin: 10px 0;
    padding: 10px 0 30px;
  }

  h5 {
    @include body7-font;
    color: $black !important;
    font-size: rem-calc(14);
    margin: 15px 0 0;
  }

  span {
    @include body8-font;
    display: block;
    margin-block-start: 1.5em;
  }

  a {
    color: $black;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .column {
    margin-block-end: rem-calc(15);
    @include breakpoint(medium down) {
      margin-block-end: 0;
    }
  }
}

.bio-detail-share {
  margin: 120px 0 0;
  @include breakpoint(small down) {
    margin: 20px 0 0;
  }

  li {
    @include body8-font;
    color: $black;
    display: inline-block;
    list-style: none;
    padding-inline-end: rem-calc(50);

    img,
    svg {
      font-size: inherit;
      margin-inline-end: 0.7em;
    }

    a {
      color: #000;
      text-decoration: none;
    }
  }

  .bio-download {
    img,
    svg {
      vertical-align: middle;
    }
  }

  .bio-print {
    img,
    svg {
      inset-block-start: -1px;
      position: relative;
    }
  }
}

.dropdown-pane {
  background: #2d3a4a;
  block-size: 52px;
  border-block-end: none;
  border-block-start: 10px solid $white;
  border-inline-end: none;
  border-inline-start: none;
  inline-size: fit-content;
  outline: none;
  padding: 6px 10px 10px;
  visibility: visible;

  &[hidden] {
    visibility: hidden;
  }

  &::before {
    color: #2d3a4a;
    content: "\f0d8";
    font-family: FontAwesome;
    font-size: 1rem;
    inset-block-start: -15px;
    inset-inline-start: 4px;
    position: absolute;
  }

  .social-list {
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      list-style: none;
      padding-inline-end: 20px;

      a {
        @include body10-font;

        &:hover {
          text-decoration: underline;
        }
      }

      &:last-child {
        padding-inline-end: 0;
      }
    }

    .social-list-icon {
      display: block;
      inline-size: 23px;
      //outline: 0;
      // &:hover {
      //     opacity: .5;
      // }
    }
  }
}

.dropdown-pane.white-share {
  background: #fff;
  block-size: 52px;
  border-block-end: none;
  border-block-start: 10px solid $primary-color;
  border-inline-end: none;
  border-inline-start: none;
  inline-size: 185px;
  outline: none;
  padding: 6px 10px 10px;

  &::before {
    color: $white;
    content: "\f0d8";
    font-family: FontAwesome;
    font-size: 1rem;
    inset-block-start: -15px;
    inset-inline-start: 4px;
    position: absolute;
  }

  .social-list {
    margin: 0;
    padding: 0;

    li {
      border-block-end: 0 !important;
      display: inline-block;
      list-style: none;
      padding-inline-end: 20px;

      a {
        @include body10-font;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      &:last-child {
        padding-inline-end: 0;
      }
    }

    .social-list-icon {
      display: block;
      inline-size: 23px;
      // &:hover {
      //     opacity: .5;
      // }
    }
  }
}

.slide-button {
  @include breakpoint(small down) {
    display: none;
  }

  .contact {
    display: none;
  }

  &.slide-content-open {
    .contact {
      display: block;
    }

    .see-more {
      display: none;
    }
  }
}

/* pre-VI-update version of the bio lead-paragraph classes */

// .bio-lead {
//     display: block;
//     font-size: 1.375rem;
//     font-style: italic;
//     font-family: Georgia,Times,Times New Roman,serif;
//     font-weight: 400;
//     letter-spacing: 0;
//     line-height: 1.6;
// }
// .bio-attribution {
//     display: block;
//     font-size: 1.375rem;
//     font-style: normal;
//     font-family: Georgia,Times,Times New Roman,serif;
//     font-weight: 400;
//     letter-spacing: 0;
// }
// @media (max-width: 640px) {
//     .bio-lead, .bio-attribution {
//         font-size: 1rem;
//     }
// }
// @media (min-width:641px) and (max-width: 1024px) {
//     .bio-lead, .bio-attribution {
//         font-size: 1.125rem;
//     }
// }

/* VI-update version of the bio lead-paragraph classes */

.bio-lead {
  @include body1-font;
  display: block;
  font-size: 1.5rem; //rem-calc(24);
  font-style: normal;
  line-height: 1.4;
}

.bio-attribution {
  @include body1-font;
  display: block;
  font-size: 1rem;
  font-style: normal;
}

@media (min-width: 641px) and (max-width: 1024px) {
  .bio-lead {
    font-size: 1.125rem;
  }
}
@media (min-width: 641px) and (max-width: 1024px) {
  .bio-attribution {
    font-size: 0.875rem;
  }
}

@media (max-width: 640px) {
  .bio-lead {
    font-size: 1rem;
  }
}
@media (max-width: 640px) {
  .bio-attribution {
    font-size: 0.8rem;
  }
}

h1.bio-h1,
h2.bio-h1 {
  @include displayXL-font;
}

.position-relative {
  position: relative;
}

a.social-list-icon:focus {
  outline: none;

  img {
    outline: solid 1px yellow;
  }

  svg {
    outline: solid 1px yellow;
  }
}

#bio-overview {
  .social-lockup {
    display: block;
    margin: 40px 0;

    ul {
      display: flex;
      list-style: none;
      margin: 0;
      @media all and (max-width: 640px) {
        display: inline-block;
        margin: 0;
      }
    }

    li {
      display: inline-block;
      list-style: none;
      padding-inline-end: 15px;
      @media all and (max-width: 325px) {
        padding-inline-end: 5px;
      }
    }

    @media all and (max-width: 640px) {
      border-block-end: 1px solid #ebebeb;
      border-block-start: 1px solid #ebebeb;
      inline-size: 100%;
      margin: 0 0 40px;
      padding: 15px 0 38px;
    }
    @media all and (max-width: 375px) {
      border-block-end: 1px solid #ebebeb;
      border-block-start: 1px solid #ebebeb;
      inline-size: 100%;
      margin: 0 0 40px;
      padding: 15px 0 38px;
    }

    .dropdown-pane {
      background: #2d3a4a;
      block-size: 50px;
      border-block-end: none;
      border-block-start: 10px solid $white;
      border-inline-end: none;
      border-inline-start: none;
      inline-size: fit-content;
      outline: none;
      padding: 6px 10px 10px;

      &::before {
        color: #2d3a4a;
        content: "\f0d8";
        font-family: FontAwesome;
        font-size: 1rem;
        inset-block-start: -15px;
        inset-inline-start: 4px;
        position: absolute;
      }

      .social-list {
        margin: 0;
        padding: 0;

        li {
          display: inline-block;
          list-style: none;
          padding-inline-end: 20px;

          a {
            @include body10-font;
            margin-block-start: 24px;
            position: relative;

            &:hover {
              text-decoration: underline;
            }

            img {
              inset-block-end: 0;
              min-block-size: 17px;
              position: absolute;
            }
          }

          &:last-child {
            padding-inline-end: 0;
          }
        }

        .social-list-icon {
          display: block;
          inline-size: 23px;
          // &:hover {
          //     opacity: .5;
          // }
          // &:focus {
          //   outline: none;
          // }
        }
      }
    }
  }
}
