// ** Containers ** //
#mainform {
  // Sitecore form
  background-color: inherit;
}

#bodyTag:not([class="dark homepage"]) {
  overflow: hidden;

  #content {
    height: 100vh;
    overflow: auto;
  }
}

#bodyTag {
  @include breakpoint(1025 up) {
    margin-right: 344px;
  }
}

#content {
  background-color: inherit;
  display: block;
  inline-size: 100%;
  min-block-size: 100vh;
  position: relative;
  z-index: 2;

  @include breakpoint(small down) {
    background-image: none;
  }
}

.homepage {
  #content {
    background-image: url("/assets/images/Parabola1.png");
    background-position: left -19% top 61%;
    background-repeat: no-repeat;
    @include breakpoint(medium down) {
      background-position: bottom right;
    }
    @include breakpoint(small down) {
      background-image: none;
    }
    @include breakpoint(1400 up) {
      background-position: left 31% top 54%;
    }
    @include breakpoint(1800 up) {
      background-position: left 58% top 52%;
    }
    // Prevent background image "flash" while loading
    > [ng-app] {
      &[ng-cloak],
      &.ng-loading {
        background-color: inherit;
        min-block-size: 100vh !important;
      }
    }
  }

  .main-footer .columns {
    padding-left: 0;
  }
}

.biopage {
  #content {
    background-image: url("/assets/images/Parabola4.png");
    background-position: bottom right 345px;
    background-repeat: no-repeat;
    @include breakpoint(medium down) {
      background-position: bottom right;
    }
    @include breakpoint(small down) {
      background-image: none;
    }
  }
}

.thefirm,
.ourpeople {
  #content {
    background-image: url("/assets/images/Parabola3.png");
    background-position: bottom right 345px;
    background-repeat: no-repeat;
    @include breakpoint(medium down) {
      background-position: bottom right;
    }
    @include breakpoint(small down) {
      background-image: none;
    }
    // Prevent background image "flash" while loading
    > [ng-app] {
      &[ng-cloak],
      &.ng-loading {
        background-color: inherit;
        min-block-size: 100vh !important;
      }
    }
  }
}

.ourwork,
.careers,
.dark.insights {
  #content {
    background-image: url("/assets/images/Parabola2.png");
    background-position: bottom right 345px;
    background-repeat: no-repeat;
    @include breakpoint(medium down) {
      background-position: bottom right;
    }
    @include breakpoint(small down) {
      background-image: none;
    }
    // Prevent background image "flash" while loading
    > [ng-app] {
      &[ng-cloak],
      &.ng-loading {
        background-color: inherit;
        min-block-size: 100vh !important;
      }
    }
  }
}

#content-contain {
  display: block;
  padding: rem-calc(100) 22% rem-calc(100) 12%;
  position: relative;
  @include breakpoint(large down) {
    padding-inline: 8% 18%;
  }
  @include breakpoint(medium down) {
    padding-inline: 5%;
  }
  @include breakpoint(small down) {
    padding-inline: 6%;
  }
}

@include breakpoint(small down) {
  .mobile-subnav + #content-contain {
    padding-block-start: rem-calc(140);
  }

  .button-slideout-insight + #content-contain {
    padding-block-start: rem-calc(140);
  }
}

#content-full-contain {
  display: block;
  padding: 0;
  position: relative;
}

.white {
  background-color: #fff;

  p,
  li {
    color: $black !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $primary-color !important;
  }

  .mobile-subnav {
    .tabs-title,
    .toggle-mobile-subnav {
      color: $white !important;
      background-color: #2d3a4a !important;
    }
  }
}

.toggle-mobile-subnav {
  background-color: #2d3a4a;


}

.dark {
  background-color: $primary-color;
  color: $white;

  p,
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: $white;
  }
}

.off-canvas-content {
  box-shadow: none;
  min-block-size: 100vh;
  position: relative;
  z-index: 1;
}

.off-canvas.position-right {
  @media all and (min-width: 1241px) {
    inline-size: 345px;
    inset-block-start: 0;
  }
  @media all and (max-width: 1240px) {
    inline-size: 260px;
    inset-block-start: 0;
  }
}

.position-right.reveal-for-large ~ .off-canvas-content {
  @media all and (min-width: 1241px) {
    margin-inline-end: 345px;
  }
  @media all and (min-width: 1024px) and (max-width: 1240px) {
    margin-inline-end: 245px;
  }
}

.hr-break {
  block-size: auto;
  display: block;
  margin: 40px 0 70px;
  @include breakpoint(small only) {
    margin: -11px 0 50px;
  }

  hr {
    border-color: #d2d2d2;
  }
}

.hr-break-white {
  block-size: auto;
  display: block;
  margin: 80px 0;

  hr {
    border-color: #979797;
    opacity: 0.2;
  }
}

.inpage-section-block {
  display: block;
  margin-block-start: rem-calc(57);
  position: relative;
  @include breakpoint(small only) {
    margin-block-start: rem-calc(52);
  }
}

.inpage-sub-section-block {
  display: block;
  margin: rem-calc(35) 0;
  position: relative;
  @include breakpoint(small only) {
    margin-block-end: 0;
  }
}
