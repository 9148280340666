/* Base for label styling */

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  inset-inline-start: -9999px;
  position: absolute;
}

[type="checkbox"]:not(:checked)+label,
[type="checkbox"]:checked+label {
  cursor: pointer;
  padding-inline-start: 14px;
  position: relative;
}

input[type="checkbox"]:focus+label {
  border: 1px solid #f8e71c;
}

/* checkbox aspect */

[type="checkbox"]:not(:checked)+label::before {
  background: none;
  block-size: 8px;
  border: 1px solid #fff;
  content: "";
  inline-size: 8px;
  inset-block-start: 6px;
  inset-inline-start: 0;
  position: absolute;
}

[type="checkbox"]:checked+label::before {
  background-color: $white;
  block-size: 8px;
  border: 1px solid #fff;
  content: "";
  inline-size: 8px;
  inset-block-start: 6px;
  inset-inline-start: 0;
  position: absolute;
}

[type="checkbox"]:checked:focus+label::before,
[type="checkbox"]:checked+label::before {
  border: none;
}


/* checked mark aspect changes */

[type="checkbox"]:not(:checked)+label::after {
  opacity: 0;
  transform: scale(0);
}

[type="checkbox"]:checked+label::after {
  opacity: 1;
  transform: scale(1);
}


/* disabled checkbox */

[type="checkbox"]:disabled:not(:checked)+label::before,
[type="checkbox"]:disabled:checked+label::before {
  background-color: #ddd;
  border-color: #bbb;
  box-shadow: none;
}

[type="checkbox"]:disabled:checked+label::after {
  color: #999;
}

[type="checkbox"]:disabled+label {
  color: #aaa;
}


/* accessibility */

[type="checkbox"]:not(:checked):focus+label::before {
  border: 1px solid #fff;
}

.consent-checkbox,
.language-checkbox {
  margin-block-end: rem-calc(20);
  /* Base for label styling */
  [type="checkbox"]:not(:checked),
  [type="checkbox"]:checked {
    inset-inline-start: -9999px;
    position: absolute;
  }

  [type="checkbox"]:not(:checked)+label,
  [type="checkbox"]:checked+label {
    cursor: pointer;
    padding-inline-start: 24px;
    position: relative;
  }
  /* checkbox aspect */
  [type="checkbox"]:not(:checked)+label::before,
  [type="checkbox"]:checked+label::before {
    background: none;
    block-size: 15px;
    border: 1px solid #fff;
    content: "";
    inline-size: 15px;
    inset-block-start: 2px;
    inset-inline-start: 2px;
    position: absolute;
  }
  /* checked mark aspect */
  [type="checkbox"]:not(:checked)+label::after,
  [type="checkbox"]:checked+label::after {
    @include body10-font;
    color: #fff;
    content: "x";
    inset-block-start: 4px;
    inset-inline-start: 6px;
    line-height: 0.8;
    position: absolute;
    transition: all 0.2s;
  }
  /* checked mark aspect changes */
  [type="checkbox"]:not(:checked)+label::after {
    opacity: 0;
    transform: scale(0);
  }

  [type="checkbox"]:checked+label::after {
    opacity: 1;
    transform: scale(1);
  }
  /* disabled checkbox */
  [type="checkbox"]:disabled:not(:checked)+label::before,
  [type="checkbox"]:disabled:checked+label::before {
    background-color: #ddd;
    border-color: #bbb;
    box-shadow: none;
  }

  [type="checkbox"]:disabled:checked+label::after {
    color: #999;
  }

  [type="checkbox"]:disabled+label {
    color: #aaa;
  }
  /* accessibility */
  [type="checkbox"]:checked:focus+label::before,
  [type="checkbox"]:not(:checked):focus+label::before {
    border: 1px solid #fff;
  }
}

select {
  @include body9-font;
  background: none;
  background-image: url(/assets/images/dropdownarrow2.png);
  background-position: right 5px top 0;
  background-repeat: no-repeat;
  border: 1px solid $white;
  border-radius: 0;
  color: $white;
  max-block-size: 30px;
  padding: 0 12px;

  option {
    color: $primary-color;
  }
}

label {
  color: $white;
  font-size: rem-calc(12);
  margin-block-end: 10px;
}

input {
  background: none;
  border-radius: 0;
  color: $white;
  max-block-size: 30px;
  padding: 0 12px;
}

input[type="text"],
input[type="email"],
input:not([type]) {
  margin-block-end: 25px;
  @include breakpoint (small only) {
    font-size: rem-calc(16) !important;
  }
}

.is-invalid-input:not(:focus) {
  background-color: rgba(255, 0, 31, 0);
  border-color: #ff001f;
}

.subscribe-alert {
  color: #ff7686 !important;
  font-size: rem-calc(14);
  // font-style: italic;
  font-weight: normal;
  margin: 15px 0;
}

.form-error {
  color: #ff7686;
  display: none;
  font-family: Arial, Helvetica, sans-serif;
  font-size: rem-calc(12);
  // font-style: italic;
  font-weight: normal;
  margin-block: -1.2rem 1rem;
}

.form-error-main {
  font-family: Arial, Helvetica, sans-serif;
}

.subscribe-form {
  ::input-placeholder {
    @include body9-font;
    color: #999;
  }

  ::placeholder {
    @include body9-font;
    color: #999;
  }

  :input-placeholder {
    @include body9-font;
    color: #999;
  }

  :placeholder {
    @include body9-font;
    color: #999;
  }
}

.subscribe-interests {
  display: block;
  margin-block: rem-calc(80);
}

// Required
input:not([type]) {
  display: block;
  inline-size: 100%;
}
