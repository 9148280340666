.session-outline a {
  color: $white;
}

.authors-block {
  color: $black !important;
  display: block;
  margin-block-end: rem-calc(40);
  font-family: Georgia,Times,Times New Roman,serif;
  font-weight: 400;
  line-height: 1.5;
  font-size: 16px;

  p {
    @include body2-font;
    color: $black !important;
  }

  .article-date {
    color: $primary-color !important;
  }

  a {
    text-decoration: underline;
    font-family: Georgia,Times,Times New Roman,serif;
    color: #2d3a4a;
    &:hover {
      text-decoration: none;
    }
  }
}

.academy-disclaimer {
  margin-block-start: 50px;
}

.practice-list {
  margin: 0 0 40px;

  h5 {
    @include body7-font;
    color: $black;
    margin-block-end: rem-calc(5);
  }

  li {
    color: $black;
    font-size: rem-calc(14);
    list-style: none;
    @media all and (max-width: 640px) {
      display: block;
    }
  }

  .email-link {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .phone-link {
    color: $black;
    text-decoration: none;
  }
}

.academy-details {
  h3 {
    margin-block-start: rem-calc(40);
  }
}

.KeyContactsVisibility-hide {
  display: none;
  opacity: 0;
  transition: opacity 0.5s;
}

.KeyContactsVisibility-show {
  display: block;
}

.insight-title {
  @include body6-font;
  color: $primary-color;
  display: block;
  font-size: rem-calc(12);
  letter-spacing: 0.5px;
  line-height: 1.2;
  margin-block-end: rem-calc(15);
  text-transform: uppercase;
}

/* to put some space around the italics in titles (h1 - Insights search results; h4 - all other search results) */
h1 em {
  margin: 0 12px 0 5px;
}

h4 a em {
  margin: 0 8px 0 4px;
}
