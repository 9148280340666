ul,
ol {
  li {
    font-size: rem-calc(14);
    padding-block-end: rem-calc(10);
  }
}

.link-list {
  li {
    list-style: none;
    margin-block-end: rem-calc(5);
    margin-inline-start: rem-calc(20);

    a {
      color: $white !important;

      &:hover {
        color: $text-link-blue !important;
      }
    }

    &:last-child {
      margin-block-end: rem-calc(-15);
    }
  }
}

.link-list-dark {
  margin: 0;
  padding: 0;

  li {
    @include body8-font;
    list-style: none;
    margin-block-end: rem-calc(5);

    a {
      color: $black;
      text-decoration: underline;

      &:hover {
        color: $text-link-blue !important;
        text-decoration: none;
      }
    }
  }
}

.visiting-list {
  margin-block-end: 20px !important;
  margin-inline-start: 40px !important;

  span {
    @include body7-font;
    display: block;
    margin-block-end: rem-calc(5);
  }

  li {
    font-size: rem-calc(14);
    list-style: disc;
  }
}

.detailed-list {
  margin-inline-start: rem-calc(20);
  padding: 0;

  li {
    margin-block-end: rem-calc(15);

    span {
      @include body8-font;
      display: block;
    }
  }

  .inset-item {
    margin-inline-start: rem-calc(40px);
  }
}

.people-info {
  line-height: 1;
  margin: 0;
  padding: 0;

  li {
    @include body8-font;
    border-inline-end: 1px solid $text-link-blue;
    color: $text-link-blue;
    display: inline-block;
    list-style: none;
    padding: 0 5px 0 0;
    @include breakpoint (small only) {
      border: none;
      display: block;
      font-size: rem-calc(14);
      margin-block-end: 8px;
      padding: 0;

      &:first-child {
        margin-block-start: 10px;
      }

      &:last-child {
        margin-block-end: 5px;
      }
    }

    &:last-child {
      border: none;
    }

    a {
      color: $text-link-blue;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

// .insights #content-contain .inpage-section-block ul, .thefirm #content-contain .inpage-section-block ul  {
//   list-style-image: url('/assets/images/bullet.png') !important;
//   margin-top: rem-calc(8);
// }
#content-contain {
  ul:not(.tabs,.tabs-title,.is-submenu-item,.link-list,.link-list-dark) {
    list-style-image: url("/assets/images/bullet.png") !important;

    & li {
      margin-block-start: rem-calc(5);
    }
  }    
}

.dark #content-contain {
  ul:not(.tabs,.tabs-title,.is-submenu-item,.link-list,.link-list-dark) {
    // list-style-image: url("/assets/images/bullet_black.png") !important;

    & li {
      margin-block-start: rem-calc(5);
    }
  }    
}

.dark #content-contain .recognition-block {
  ul:not(.tabs-title) {
    list-style-image: url("/assets/images/bullet.png") !important;

    & li {
      margin-block-start: rem-calc(5);
    }
  }    
}

#content-contain {
  ul.tabs {
    list-style-image: none !important;

    & li {
      margin-block-start: rem-calc(5);
    }
  }  
}

#content-contain .inpage-list-menu {
  ul:not(.tabs-title) {
    list-style-image: none !important;

    & li {
      margin-block-start: rem-calc(5);
    }
  }    
}

.students-city-articling {
  ul:not(.tabs-title) {
    list-style-image: url("/assets/images/bullet_black.png") !important;

    & li {
      margin-block-start: rem-calc(5);
    }
  }    
}

.students-city-summer-program {
  ul:not(.tabs-title) {
    list-style-image: url("/assets/images/bullet.png") !important;

    & li {
      margin-block-start: rem-calc(5);
    }
  }    
}

#content-contain, .page-container {
  .inpage-section-block {
    ul:not(.tabs-title,.is-submenu-item,.link-list,.link-list-dark) {
      list-style-image: url("/assets/images/bullet.png") !important;

      & li {
        margin-block-start: rem-calc(5);
      }
    }    
  }
}
//ul {margin:10px 0 13px 0; padding-left:20px; line-height:1.4; list-style-image: url('/assets/images/bullet.png');}
//.insights #content-contain .inpage-section-block .social-list {
/* the social share icons had too much top-margin, but only in the Insights section */
//margin-top: rem-calc(-2);
//}

