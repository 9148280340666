.page-container {
  block-size: 100vh;
  inline-size: 100%;
  overflow: clip;
  position: relative;
  transition: opacity 0.2s ease-in-out;

  &.visually-hidden {
    @include breakpoint(small down) {
      opacity: 0;
    }
  }
}

.page-slider__container {
  display: flex;
  flex-flow: row;
  inline-size: 100%;
  position: relative;
  transition: transform 500ms ease-in-out;
}

.slide-page-open {
  min-block-size: 100%;
  transform: translateX(-100%);
}

.page-slider__primary-page {
  block-size: 100vh;
  flex-shrink: 0;
  inline-size: 100%;
  min-block-size: 100vh;
  overflow-y: auto;
  padding: 8.75rem 5% 2rem;
  position: relative;

  @include breakpoint(large up) {
    padding: 6.25rem 20% 2rem 8%;
  }

  @include breakpoint(1200px up) {
    padding: 6.25rem 22% 6.25rem 12%;
  }

  .main-footer {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.page-slider__secondary-page {
  block-size: 100vh;
  color: $primary-color;
  flex-shrink: 0;
  inline-size: 100%;
  position: relative;

  * {
    color: inherit !important;
  }

  .back-to-left-side {
    background-color: $primary-color;
    block-size: 100%;
    cursor: pointer;
    display: block;
    inline-size: 10%;
    inset-block-start: 0;
    inset-inline-start: 0;
    position: absolute;
    z-index: 1;

    @include breakpoint(small down) {
      display: none;
    }
  }

  .page-slider__container {
    background-color: $white;
    block-size: 100%;
    display: flex;
    flex-flow: column;
    overflow-y: auto;
  }

  .page-slider__content {
    flex-grow: 1;
  }

  footer {
    color: $primary-color !important;
    padding-inline: 5% !important;

    @include breakpoint(medium up) {
      padding-inline-start: 15% !important;
    }

    @include breakpoint(large up) {
      padding-inline: 20% 18% !important;
    }

    @include breakpoint(1200px up) {
      padding: 6.25rem 20% !important;
    }

    svg {
      fill: $primary-color !important;
    }
  }

  .main-footer {
    .column {
      padding: 0;
    }
  }
}

.drawer-container {
  .mobile-subnav {
    color: $white !important;
    position: sticky;
  }
}

.drawer-content {
  padding: 6.25rem 5% 0;

  @include breakpoint(medium up) {
    padding-inline-start: 15%;
  }

  @include breakpoint(large up) {
    padding: 6.25rem 20% 0 18%;
  }

  @include breakpoint(1200px up) {
    padding: 6.25rem 20% 0;
  }

  .transition-back-button {
    padding-block-end: 1.5rem;
  }
}
