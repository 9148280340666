$menu-width: 345px;

.button {
  @include body6-font;
  background: none;
  line-height: 1.2;
  padding: 0;

  &::after {
    background: none;
    border-block-end: 2px $secondary-color solid;
    content: "";
    display: block;
    transform: scaleX(1);
    transform-origin: 100%;
    transition: transform 350ms ease;
  }

  &:hover {
    background: none;

    &::after {
      transform: scaleX(0);
      transform-origin: 100%;
    }
  }

  &:focus {
    background: none;
  }
}

.button.secondary {
  @include body6-font;
  background: none;
  color: $primary-color;
  padding: 0;

  &::after {
    background: none;
    border-block-end: 2px $bright-blue solid;
    content: "";
    display: block;
    transform: scaleX(1);
    transform-origin: 100%;
    transition: transform 350ms ease;
  }

  &:hover {
    background: none;
    color: $primary-color;
    opacity: 0.7 !important;

    &::after {
      transform: scaleX(0);
      transform-origin: 100%;
    }
  }

  &:focus {
    background: none;
    color: $primary-color;
  }
}

.back-button {
  @include body3-font;
  color: $primary-color;
  padding-inline-start: 15px;
  position: relative;

  &::before {
    border: none;
    color: #2d3a4a;
    content: "\f060";
    font-family: FontAwesome;
    font-size: 11px !important;
    inset-block-start: 0;
    inset-inline-start: 0;
    outline: none;
    position: absolute;
  }
}

.generic-back-button {
  inset-block-start: 0;
  margin-block-start: 2rem;
  position: absolute;
  @include breakpoint(medium down) {
    margin-block-start: 0;
    padding-block-end: 1.5rem;
    position: relative;
  }
}

.students-transition-back-button {
  margin-block: -70px 50px;
  @include breakpoint (medium only) {
    margin-block: 0 50px;
  }
  @include breakpoint (small only) {
    margin-block: 0 20px;
    padding-inline: 6%;
  }

  .back-button {
    @include body3-font;
    color: $white;
    position: relative;
    @include breakpoint (small only) {
      color: $primary-color;
    }

    &::before {
      border: none;
      color: $white;
      content: "\f060";
      font-family: FontAwesome;
      font-size: 0.725em;
      inset-block-start: 0;
      inset-inline-start: 0;
      outline: none;
      position: absolute;
      @include breakpoint (small only) {
        color: $primary-color;
      }
    }
  }
}

.white .students-transition-back-button .back-button {
  color: $white;

  &::before {
    color: $white;
  }
}

.students-transition-back-button-white {
  margin-block: -70px 50px;
  @include breakpoint (medium only) {
    margin-block: 0 50px;
  }
  @include breakpoint (small only) {
    margin-block: 10px 20px;
  }

  .back-button {
    @include body8-font;
    color: $primary-color;
    position: relative;
    text-decoration: none;
    @include breakpoint (small only) {
      color: $primary-color;
    }

    &::before {
      border: none;
      color: $primary-color;
      content: "\f060";
      font-family: FontAwesome;
      font-size: 0.725em;
      inset-block-start: 0;
      inset-inline-start: 0;
      outline: none;
      position: absolute;
      @include breakpoint (small only) {
        color: $primary-color;
      }
    }
  }
}

.contact-fixed {
  display: block;
  inset-block-start: rem-calc(250);
  margin-inline-start: 20px;
  position: fixed;
}

.subscribed-fixed {
  display: block;
  inset-block-start: rem-calc(460);
  margin-inline-start: 20px;
  position: fixed;
}

.apply-fixed {
  display: block;
  inset-block-start: rem-calc(260);
  margin-inline-start: 20px;
  position: fixed;
}

.session-download {
  @include body8-font;
  display: block;
  margin-block-end: 0.9375rem !important;
  @include breakpoint (small down) {
    margin-block-start: rem-calc(30);
  }

  &::before {
    content: url("../img/DownloadArrowWhite.png");
    margin-inline-end: 5px;
  }
}

.session-download-dark {
  @include body8-font;
  color: $black !important;
  display: block;
  margin-block-end: 0.9375rem !important;
  @include breakpoint (small down) {
    margin-block-start: rem-calc(30);
  }

  &::before {
    content: url("../img/DownloadArrow.png");
    margin-inline-end: 5px;
  }
}

.session-video {
  @include body8-font;
  display: block;

  &::before {
    content: url("../img/PlayIconWhite.png");
    margin-inline-end: 8px;
  }
}

.session-video-dark {
  @include body8-font;
  color: $black !important;
  display: block;

  &::before {
    content: url("../img/PlayIcon.png");
    margin-inline-end: 8px;
  }
}

.submit-white {
  @include body10-font;
  background: $white;
  border-radius: 0;
  color: #2d3a4a;
  display: block;
  margin: 20px 0;
  padding: 8px 50px;
  transition: background-color 0.3s ease;

  &:hover {
    background: $secondary-color;
  }
}

.tel-link {
  @include body3-font;
  color: $white;
  text-decoration: underline;

  &:hover {
    color: $white;
    text-decoration: underline;
  }
}

.tel-link-serif {
  color: $white;
  text-decoration: underline;

  &:hover {
    color: $grey-hover;
    text-decoration: none;
  }
}

.button-slideout {
  display: block;
  inline-size: 16%;
  inset-block-start: 290px;
  inset-inline-end: 345px;
  position: fixed;
  text-align: center;
  z-index: 800;
  @include breakpoint (medium only) {
    inline-size: 175px;
    inset-inline-end: 0;
  }
  @include breakpoint (small only) {
    display: none;
  }
}

[class*="button-slideout"]:not([class*="-inline"]) {
  display: block;
  inline-size: auto;
  inset-block-start: 230px;
  inset-inline-end: $menu-width;
  margin-inline-end: 5%;
  opacity: 1;
  position: fixed;
  text-align: center;
  z-index: 800;
  @include breakpoint(large down) {
    margin-inline-end: 3.5%;
  }
  @include breakpoint (medium down) {
    inset-inline-end: 0;
    margin-inline-end: 1rem;
    transition: all 300ms ease, transform 500ms ease-out;

    .js-show-navigation & {
      transform: translateX(-$menu-width);
    }
  }
  @include breakpoint (small only) {
    display: none;
  }
}

.button-slideout-contact {
  inset-block-start: 260px;
}

.button-slideout-insight {
  inset-block-start: 380px;
}

.button-slideout-apply {
  inset-block-start: 230px;
  position: absolute;

  .touch-disabled & {
    position: fixed;
  }

  &.js-white,
  &.js-blue {
    .button {
      color: inherit;
      transition: none;
    }
  }

  &.js-white {
    color: $white;
  }

  &.js-blue {
    color: $primary-color;

    .button::after {
      border-block-end: 2px $bright-blue solid;
    }
  }

  &:hover {
    opacity: 0.7;
  }
}

.button-slideout-apply-inline {
  display: none;
  @include breakpoint(medium down) {
    display: inline-block;
  }

  .touch-enabled & {
    display: inline-block;
  }
}

.view-all {
  .less {
    display: none;
  }

  .all {
    display: block;
  }
}

.toggle-on {
  .less {
    display: block;
  }

  .all {
    display: none;
  }
}

.social-drop-button {
  &:hover {
    opacity: 0.5;
  }
}

.email-link-icon {
  color: $black !important;
  display: block;
  font-size: rem-calc(14);
  margin-block-end: 0.9375rem !important;
  text-decoration: none;

  &::before {
    content: url("../img/Icon_Email_dark_button.png");
    margin-inline-end: 5px;
  }
}
