.our-work-section {
  @include breakpoint(small down) {
    margin-block-start: rem-calc(50);
  }
}

.our-work-section-tabs {
  h6 {
    margin: 40px 0 30px;
  }
}

.key-contacts-info {
  margin-block-end: rem-calc(40);

  .email-link-icon {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  img {
    inline-size: 160px;
    margin-block-end: rem-calc(25);
  }

  h6 {
    @include body6-font;
    margin-block-end: rem-calc(12);

    &:hover {
      opacity: 0.5;
    }
  }

  p {
    @include body3-font;
    line-height: 1.5;
    margin-block-end: 7px;
  }

  a {
    &:hover {
      opacity: 0.5;
    }
  }

  .key-contact-name {
    display: block;
    max-inline-size: 160px;

    a{
      text-decoration: none;
    }
  }
}

.date-range {
  display: block;
  margin-block-start: rem-calc(10);
}

.expertise-lists {
  display: block;
  margin-block-start: rem-calc(22);
  @include breakpoint(small down) {
    margin-block-start: rem-calc(20);
  }

  h6 {
    margin-block-end: 27px;
    @include breakpoint(small down) {
      margin-block-start: rem-calc(35);
    }
  }

  ul li::marker {
    content: "";
  }

  a,
  li a {
    text-decoration: none;
  }

  .js-highlight-tabs a {
    color: #fefefe;
    font-family: "NHaasGroteskDSStd-55Rg", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
  }

  li li {
    margin-inline-start: 10px;
  }
}


/* make the client name in the Our Work/Rep Work tab the same size as in the Our Work/Overview/Rep Work section */

.ourwork {
  article {
    p.strong {
      font-family: "NHaasGroteskDSStd-65Md", Helvetica, Arial, sans-serif;
      font-size: 1rem;
    }
  }

  .date-block h6 {
    font-size: 1rem;
  }
}

.ourwork {
  .white {
    .latest-block {
      a {
        text-decoration: none;
      }
      a:hover {
        color: #ccc;
      }
    }
  }
}
