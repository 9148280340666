.vertical-content-tab {
  display: flex;
  margin-block-end: 35px;

  .tabs {
    border-block-end: 1px solid #ebebeb;
    border-block-start: 1px solid #ebebeb;
    display: flex;
    flex-flow: wrap;
    padding-block-start: 20px;

    @include breakpoint(medium down) {
      flex-flow: column;
    }

    .tab-button {
      inline-size: 50%;
      margin-block-start: 5px;
      padding-block-end: 20px;

      @include breakpoint(medium down) {
        inline-size: 100%;
      }

      a {
        color: #2d3a4a;
      }

      &:last-child {
        padding: 0;
        padding-block-end: 20px;
      }
    }
  }

  .on-dark {
    .tab-button {
      color: #fefefe !important;
    }
  }
}
