.expand-select-box {
  background-color: $primary-color;
  color: $white;
  inline-size: 100%;
  margin-block-end: rem-calc(15);
  position: relative;
  transition: height 0.15s ease-out;
  user-select: none;

  .expand-title {
    @include body10-font;
    background-image: url("/assets/images/dropdownarrow2.png");
    background-position: center right 0.65em;
    background-repeat: no-repeat;
    border: 1px solid $white;
    border-radius: 0;
    cursor: pointer;
    display: block;
    inline-size: 100%;
    padding: rem-calc(5 25 5 13);
    position: relative;
  }

  .expand-content {
    background-color: inherit;
    border: 1px solid $white;
    border-block-start: none;
    border-radius: 2px;
    border-start-end-radius: 0;
    border-start-start-radius: 0;
    display: none;
    inline-size: 100%;
    inset-block-start: 100%;
    margin-block-start: -2px;
    overflow: auto;
    padding: rem-calc(5 0);
    padding-block-end: 0;
    position: absolute;
    z-index: 5;

    &.fixed-height {
      block-size: rem-calc(225);
    }

    &.open {
      display: block;
    }
  }

  .checkbox-stack {
    background: #2d3a4a;
    display: block;
    margin-block-end: 0;
    padding: rem-calc(0 5);
    padding-block-end: 5px;
    position: relative;
  }

  .radio-stack {
    background: #2d3a4a;
    display: block;
    position: relative;

    label {
      display: block;
      inline-size: 100%;
      margin: 0;
      padding: rem-calc(5 5 5 18);
      position: relative;
      transition: background-color 150ms ease;

      &:hover,
      &:focus {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }

    input {
      inset-inline-start: -9999px;
      position: absolute;

      &:hover + label,
      &:checked + label {
        background-color: rgba(0, 0, 0, 0.2);
      }

      &:focus + label {
        border: 1px solid #f8e71c;
      }
    }
  }

  .sub-items {
    display: none;
    padding-inline-start: rem-calc(20);
  }

  label {
    @include body10-font;
    background: #2d3a4a;
    padding: 0 0.3125rem 0.3125rem 1.125rem;
  }
}
