.intro {
  display: block;
  margin-block-end: rem-calc(75);
}

#alumni {
  a {
    color: $white;
    text-decoration: underline;

    &:hover {
      opacity: 0.5;
    }
  }

  .inline-linkd-icon {
    display: inline-block;
    inset-block-start: 2px;
    margin-inline-end: 10px;
    position: relative;
  }
}
