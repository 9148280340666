$modal-transition-duration: 500ms;

.modal-window {
  background-color: $primary-color;
  block-size: 100%;
  font-size: 1rem;
  inline-size: 100%;
  inset-block-start: 0;
  inset-inline-end: 0;
  overflow: auto;
  padding-inline-end: 345px;
  position: fixed;
  transform: translateX(100%);
  transition: transform $modal-transition-duration ease;
  z-index: 2000;
  @include breakpoint(medium down) {
    inset-inline-end: 0;
    padding-inline-end: 0;
  }

  &.js-animating {
    backface-visibility: hidden;
    will-change: transform, right;
  }

  &.js-animate-in {
    inset-inline-end: 0;
    transform: translateX(0) translateY(0);
    @include breakpoint(medium down) {
      inset-inline-end: 0;
    }
  }

  &.js-morphing {
    inset-inline-end: 0;
    opacity: 0;
    transform: none;
    visibility: hidden;
    @include breakpoint(medium down) {
      padding-inline-end: 0;
    }

    &.js-animating {
      transition: opacity $modal-transition-duration ease;
      visibility: visible;
    }

    &.js-animate-in {
      opacity: 1;
      transition: opacity $modal-transition-duration ease;
      visibility: visible;
    }
  }
}

.modal-content {
  display: block;
  inline-size: 100%;
  padding: rem-calc(95) 22% rem-calc(95) 12%;
  position: relative;
  @include breakpoint (large down) {
    padding-inline: 8% 20%;
  }
  @include breakpoint (medium down) {
    padding-inline: 5%;
  }
  @include breakpoint (small down) {
    padding-inline: 6%;
  }

  h1,
  h2,
  h4,
  h6,
  p {
    color: $white !important;
  }

  label,
  .required {
    @include body10-font;
  }

  p.required {
    font-size: 0.8em;
  }

  a.link-white,
  a.tel-link {
    /* this added to make the links white on the Contact Us modal when in the Students section */
    color: $white;

    &:hover {
      color: $grey-hover;
      cursor: pointer;
      text-decoration: none !important;
    }
  }
  
  h2#modalTitle {
    font-size: 3.375rem;
    letter-spacing: 1px;
  }
}

.modal-close {
  inset-block-start: 1.25em;
  inset-inline-end: 0;
  margin-inline-end: rem-calc(365);
  opacity: 1;
  padding: 0.25em;
  position: fixed;
  transition: opacity $modal-transition-duration ease, right 0ms linear;
  transition-delay: 0ms, $modal-transition-duration;
  z-index: 2;
  @include breakpoint(medium down) {
    margin-inline-end: 1.5em;
  }

  &.js-show {
    inset-inline-end: 0;
    opacity: 1;
    pointer-events: all;
    transition-delay: 0ms, 0ms;
  }

  svg,
  img {
    block-size: 1.5em;
    fill: white;
    inline-size: 1.5em;
  }
}

.morph-into-modal {
  position: relative;

  &::after {
    block-size: 100%;
    content: "";
    cursor: text;
    display: block;
    inline-size: 100%;
    inset-block-start: 0;
    inset-inline-start: 0;
    position: absolute;
  }
}

.morph-from-input {
  &.js-animating {
    backface-visibility: hidden;
    inset-block-start: 0;
    inset-inline-start: 0;
    perspective: 1000px;
    position: fixed;
    will-change: transform, width, font-size;
    z-index: 4;
  }
}

.home-page .search-modal {
  background-color: $primary-color;
}

.modal-window {
  &.contact-modal {
    background-image: url("/assets/images/Parabola2.png");
    background-position: bottom right 345px;
    background-repeat: no-repeat;
    @include breakpoint (small down) {
      background-image: none;
    }

    .lead {
      font-size: 1.2rem;

      .link-white,
      .tel-link {
        font-size: 1.2rem;
      }
    }
  }
}

.modal-window {
  &.search-modal {
    background-image: url("/assets/images/Parabola3.png");
    background-position: bottom right 345px;
    background-repeat: no-repeat;
    @include breakpoint (medium only) {
      background-position: bottom right 0;
    }
    @include breakpoint (small down) {
      background-image: none;
    }
  }
}
