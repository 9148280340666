.tabs {
  background: none;
  border: none;
  margin-block-start: 33px;

  .tabs-title {
    @include body7-font;
    padding: 0 50px 40px 0;
    @media all and (max-width: 1280px) {
      padding: 0 30px 40px 0;
    }

    &:last-child {
      padding: 0 0 40px;
    }

    a {
      @include body8-font;
      color: $medium-blue-darker !important;
      margin: 0;
      padding: 0;
      text-decoration: none;

      &:focus,
      &:hover {
        background: none;
      }
    }

    &.is-active a {
      @include body7-font;
      background: none;
      color: $dark-blue !important;
      opacity: 1 !important;
    }
  }

  &.on-dark {
    background: none;
    border: none;

    .tabs-title {
      padding: 0 50px 40px 0;
      @media all and (max-width: 1280px) {
        padding: 0 35px 40px 0;
      }
      @media all and (max-width: 1160px) {
        padding: 0 25px 40px 0;
      }

      &:last-child {
        padding: 0 0 40px;
      }

      a {
        @include body8-font;
        color: $white !important;
        margin: 0;
        padding: 0;
        text-decoration: none;

        &:focus,
        &:hover {
          background: none;
          color: #ccc;
        }
      }

      &.is-active a {
        @include body7-font;
        background: none;
        color: $white !important;
      }
    }
  }

  .tabs-title > a[aria-selected="true"] {
    background: none;
  }
}

.tabs-panel {
  @extend .row;

  background: none;
  inline-size: 100%;
  padding: 0;

  .latest-block {
    margin: 0 0 1.875rem;

    a {
      text-decoration: none;
    }

    a:hover {
      color: #ccc;
    }
  }
}

.tabs-content {
  background: none;
  border: none;
}
