
.who-we-are-thumbnail {
  margin: rem-calc(40) 0;
}

.office-address {
  margin-block-end: rem-calc(40);
  @include breakpoint(small down) {
    margin-block-start: 15px;
  }

  h5 {
    @include body1-font;
  }

  p {
    @include body3-font;
    margin: 0;
  }

  a {
    @include body3-font;
    color: $white;
    display: block;
    margin-block-start: rem-calc(40);
    text-decoration: underline;
    @include breakpoint(small down) {
      margin: 20px 0 30px;
    }

    &:hover {
      color: $grey-hover;
    }
  }

  .phone-link {
    @include body3-font;
    color: $white;
    margin-block-start: rem-calc(0);
    text-decoration: none;
    text-decoration: underline;
  }
}

.visiting-info {
  p.strong {
    margin-block-end: 0;
  }

  a {
    color: $white;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.map-image {
  display: block;
  @include breakpoint(small down) {
    margin-block-end: 25px;
  }
}

.office-block {
  margin: 50px 0;
  @include breakpoint(small down) {
    margin: 30px 0;

    .vertical {
      margin-block-start: 30px;
    }
  }

  &:first-of-type {
    margin-block-start: 0;
  }

  &:last-of-type {
    margin-block-end: 0;
  }
  // align the map to the top of the content beside (desktop only) - 2018.02.13 (MK)
  a {
    img {
      padding-block-start: 21px;
      @include breakpoint(small down) {
        padding-block-start: 0;
      }
    }
  }
}

.infographic-section-block {
  margin-block: rem-calc(50) rem-calc(100);
}

.location-block {
  @include breakpoint(small down) {
    margin: rem-calc(10) 0;
  }

  p {
    @include body3-font;
  }
}

.callout-section {
  border-inline-start: 4px solid #f8e71c;
  display: block;
  margin-block-end: rem-calc(45);
  padding-inline-start: rem-calc(25);
  @include breakpoint (medium down) {
    p {
      font-size: rem-calc(18);
    }
  }

  p {
    @include body1-font;
    line-height: 1.6;
  }
}

.infograph-detail {
  display: inline-block;
  font-size: rem-calc(14);
  font-style: italic;
  margin-block-start: rem-calc(190);
}

.find-out-more-block {
  margin-block-start: rem-calc(20);

  p {
    font-style: italic;
    margin-block-end: rem-calc(10);
  }
}

.firm-quote-block {
  margin-block-start: rem-calc(110);

  h5 {
    font-family: "NHaasGroteskDSStd-45Lt", Helvetica, Arial, sans-serif;
  }
}

.firm-quote-block:first-of-type {
  margin-block-start: 0;
}
