#main-menu {
  width: 150px;

  .menu.vertical {
    background: none;
    margin-top: rem-calc(150);
    @media all and (max-width: 1024px) {
      margin-top: rem-calc(90);
    }
    @media all and (max-height: 680px) {
      margin-top: rem-calc(70);
    }

    li {
      &:last-child {
        padding-top: rem-calc(60);
        @media all and (max-width: 640px) {
          padding-top: rem-calc(0);
        }
        @media screen and (max-height: 680px) {
          padding-top: rem-calc(30);
        }
      }

      a {
        text-decoration: none;
      }
    }
  }

  .menu.vertical > li > a {
    @include menu-font;
    color: $primary-color;
    padding-bottom: rem-calc(25);

    &:hover,
    &:active,
    &:visited {
      @include body6-font;
      //   text-transform: uppercase;
      background: none;
    }
  }

  .menu.vertical {
    .active > a {
      @include body6-font;
      //   text-transform: uppercase;
    }
  }

  .search-menu {
    &::after {
      content: url(/assets/images/Searchicon_small_dark.png);
      font-family: FontAwesome;
      margin-left: 10px;
      position: absolute;
    }
  }
}

.dark-menu #main-menu .search-menu {
  &::after {
    content: url(/assets/images/Searchicon_small.png);
    font-family: FontAwesome;
    margin-left: 10px;
    position: absolute;
    @include breakpoint(medium down) {
      content: url(/assets/images/Searchicon_small_dark.png);
    }
  }
}

.title-bar {
  background: $primary-color;
  left: 0;
  position: fixed;
  top: 0;
  transition: left $menu-transition-duration $menu-transition-ease;
  width: 100%;
  z-index: 999;

  .menu-icon {
    height: rem-calc(20);
    width: rem-calc(22);
  }

  .title-bar-left {
    float: left;
    padding-left: 4%;
  }

  .js-show-navigation & {
    left: -$menu-size-medium;
    @include breakpoint(small down) {
      left: -$menu-size-small;
    }
    @include breakpoint(420px down) {
      left: -$menu-size-xsmall;
    }
  }
}

.lang-toggle {
  color: $primary-color;
  display: block;
  position: absolute;
  right: 20px;
  top: 30px;
  width: 70px;
  @media all and (max-width: 640px) {
    right: 0;
    top: 20px;
  }

  .lang-toggle-button {
    @include body9-font;
    color: $primary-color;
    text-align: left;
    text-decoration: none;
    text-rendering: optimizeLegibility;
    vertical-align: middle;
    width: 70px;

    &::after {
      content: url(/assets/images/menuDropdownArrow_small_dark.png);
      display: inline-block;
      margin-left: 0.5em;
      vertical-align: 15%;
    }

    &[aria-expanded="true"]::after {
      content: url(/assets/images/menuDropdownArrow_up_small_dark.png);
    }
  }

  .dropdown-pane {
    background: none;
    border: none;
    padding: 0.5rem 0;
    top: 20px !important;
    width: 70px;
    visibility: visible;

    &[hidden] {
      visibility: hidden;
    }

    &::before {
      display: none;
    }

    .lang-list {
      margin: 0;
      padding: 0;

      li {
        display: block;
        list-style: none;

        a {
          @include body9-font;
          color: $primary-color !important;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.dark-menu {
  background: #2d3a4a;

  @include breakpoint(medium down) {
    background: #fafcfe;

    #main-menu .search-menu::after {
      color: #4f5865;
    }

    #main-menu .menu.vertical > li > a {
      color: $primary-color !important;
    }

    .davies-logo {
      background-image: url("/assets/images/Davies_Logo_Light.png");
      background-repeat: no-repeat;
      background-size: contain;
      height: 23px;
      margin-top: 50px;
      width: 140px;
    }
  }

  #main-menu .menu.vertical > li > a {
    color: #fff;
  }

  .lang-toggle {
    display: block;
    position: absolute;
    right: 20px;
    top: 30px;
    width: 70px;
    @media all and (max-width: 640px) {
      right: 0;
      top: 20px;
    }

    .lang-toggle-button {
      color: #fff;
      @include breakpoint(medium down) {
        color: $primary-color;

        &::after {
          content: url(/assets/images/menuDropdownArrow_small_dark.png);
          display: inline-block;
          margin-left: 0.5em;
          vertical-align: 15%;
        }

        &[aria-expanded="true"]::after {
          content: url(/assets/images/menuDropdownArrow_up_small_dark.png);
        }
      }

      &::after {
        content: url(/assets/images/menuDropdownArrow_small.png);
        display: inline-block;
        margin-left: 0.5em;
        vertical-align: 15%;
      }

      &[aria-expanded="true"]::after {
        content: url(/assets/images/menuDropdownArrow_up_small.png);
      }
    }

    .dropdown-pane {
      border: none;
      padding: 0.5rem 0;
      width: 70px;

      .lang-list {
        margin: 0;
        padding: 0;

        li {
          display: block;
          list-style: none;

          a {
            @include body9-font;
            color: $white !important;
            @include breakpoint(medium down) {
              color: $primary-color !important; // added !important so that mobile menu lang dropdown text is visible - 2018.03.05 (MK)
            }

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

.light {
  #main-menu .menu.vertical > li > a {
    color: $primary-color;
    text-decoration: none !important;

    &:hover {
      opacity: 1;
    }
  }
}

.homepage {
  @include breakpoint(medium down) {
    .davies-logo-light {
      display: none;
    }
  }

  .davies-logo-dark {
    display: none;

    @include breakpoint(medium down) {
      display: block;
    }
  }
}

.insights,
.thefirm,
.ourpeople,
.ourwork,
.careers,
.biopage,
.white {
  .davies-logo-light {
    display: none;
  }
}

.davies-logo-dark {
  margin-left: -22px;
  margin-top: -20px;
  max-width: none;
  width: 186px;
}

.davies-logo-light {
  margin-left: -22px;
  margin-top: -20px;
  max-width: none;
  width: 186px;
}

a.davies-logo:focus {
  outline: none;
}

a.davies-logo:focus-visible {
  outline: none;

  .davies-logo-dark,
  .davies-logo-light {
    outline: -webkit-focus-ring-color auto 1px;
  }
}

.homepage {
  .mobile-davies-logo {
    display: none;
  }

  .davies-logo {
    margin-right: -22px;
    margin-top: -20px;
    width: 186px;
  }
}

/* Icon 1 */

#hamburger {
  cursor: pointer;
  height: 30px;
  margin: 5px auto;
  outline: none;
  position: relative;
  width: 26px;

  &:hover,
  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: 1px auto -webkit-focus-ring-color;
  }

  .hamburger-top,
  .hamburger-middle,
  .hamburger-bottom {
    background-color: $white;
    border-radius: 2px;
    display: block;
    height: 3px;
    left: 0%;
    position: absolute;
    transform-origin: right center;
    transition: transform 500ms ease;
    width: 100%;
  }

  .hamburger-top {
    top: 15%;
  }

  .hamburger-middle {
    margin-top: -1px;
    top: 50%;
    transform-origin: center center;
  }

  .hamburger-bottom {
    top: 75%;
  }

  &.js-open {
    .hamburger-top {
      transform: rotate(-45deg);
    }

    .hamburger-middle {
      transform: scaleX(0);
    }

    .hamburger-bottom {
      transform: rotate(45deg);
    }
  }
}

.mobile-davies-logo {
  // padding-top: 10px;
  margin-left: -10%;
  width: 120px;
}

#mainnavigation {
  color: $white;
  display: block;
  height: 100%;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  transition: transform $menu-transition-duration $menu-transition-ease;
  width: $menu-size-medium;
  z-index: 3000;

  @include breakpoint(medium down) {
    box-shadow: inset 2px 0 20px -5px rgba(0, 0, 0, 0.1);
    overflow: auto;
    transform: translateX(100%);
    z-index: 1; //1000
    .js-loaded & {
      display: block;
    }
  }
  @include breakpoint(small down) {
    padding-left: 10%;
    width: 100%;
  }

  .js-show-navigation & {
    transform: translateX(0);
  }

  &.light {
    background-color: #f9f9f9;
    box-shadow: inset 2px 0 20px -5px rgba(0, 0, 0, 0.1);

    .davies-logo-light {
      display: none;
    }

    .davies-logo-dark {
      display: block;
    }
  }
}

#content {
  transition: margin-left $menu-transition-duration $menu-transition-ease;
  @include breakpoint(medium down) {
    padding-right: 0;
  }

  #content-contain {
    transition: opacity $menu-transition-duration $menu-transition-ease;
  }

  .js-show-navigation & {
    @include breakpoint(medium down) {
      margin-left: -$menu-size-medium;

      &::after {
        content: "";
        cursor: pointer;
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 999;
      }
    }
    @include breakpoint(small down) {
      margin-left: -$menu-size-small;

      #content-contain {
        opacity: 0;
      }
    }
    @include breakpoint(420px down) {
      margin-left: -$menu-size-xsmall;
    }
  }
}

.expertise-lists {
  .inactive {
    color: hsla(0, 0%, 100%, 0.7) !important;

    &:hover {
      color: #fefefe !important;
    }

    .toggle-button {
      color: hsla(0, 0%, 100%, 0.7) !important;
      &:hover {
        color: #fefefe !important;
      }
    }
  }
}
