$angular-slide-transition-duration: 500ms;
$menu-width: 345px;

#transtion-contain {
  display: block;
  inline-size: 100%;
  overflow: hidden;
  position: relative;
  @include breakpoint(small only) {
    padding-block-start: rem-calc(60);
  }

  .transtion-left-right-wrap {
    display: block;
    inline-size: 200%;
    position: relative;

    &::after {
      clear: both;
      content: "";
      display: table;
    }

    .left-pane {
      display: block;
      float: inline-start;
      inline-size: 50%;
      min-block-size: 100vh;
      overflow: hidden;
      position: relative;
    }

    .right-pane {
      display: block;
      float: inline-end;
      inline-size: 50%;
      min-block-size: 100vh;
      overflow: hidden;
      padding: rem-calc(70) 8% 0 4%;
      position: relative;
    }

    .transition-slider {
      display: flex;
      inline-size: 100%;
      margin-inline-start: 0%;
      position: relative;
      transition: transform 500ms ease, margin-left 500ms ease;
      @include breakpoint(small down) {
        transition-duration: 0ms, 0ms;
      }
    }

    &.slide-content .transition-slider {
      display: flex;
      margin-inline-start: 2%;
      position: relative;
      transform: translateX(-50%);
      @include breakpoint(small down) {
        margin-inline-start: 0%;
      }

      .left-pane {
        cursor: pointer;
        @include breakpoint(small down) {
          block-size: 0;
        }
      }
    }
  }
}

.see-more-button {
  position: absolute;
  left: 80%;
  top: 0px;
  .slide-content & {
    opacity: 0;
    transition: none;
  }
  &.right-side {
    top: 500px;
    right: -100%;
    transition: right 250ms ease;
    .slide-content & {
      opacity: 1;
      right: 0%;
    }
  }
  @include breakpoint(medium down) {
    margin-right: rem-calc(40);
  }
}

.slide-container {
  inline-size: 100%;
  overflow: hidden;
  position: relative;

  &.ng-loading .slide-content {
    transition: none !important;
  }

  #content-contain {
    padding-block-end: 2rem;
  }

  .slide-content {
    inline-size: 100%;
    min-block-size: 100vh;
    position: relative;
    transition: transform $angular-slide-transition-duration ease-in-out;

    &.right-side {
      background-color: $white;
      block-size: 100%;
      color: $primary-color;
      padding-inline-start: 10%;
      @include breakpoint(small down) {
        padding-inline-start: 0;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        color: inherit;
      }

      a {
        color: $dark-blue;

        &:hover {
          opacity: 0.7;
        }
      }

      .back-to-left-side {
        background-color: $primary-color;
        block-size: 100%;
        cursor: pointer;
        display: block;
        inline-size: 10%;
        inset-block-start: 0;
        inset-inline-start: 0;
        position: absolute;
        @include breakpoint(small down) {
          display: none;
        }
      }
    }
  }

  .tabs-panel {
    padding: 0;
  }

  .transition-back-button {
    inset-block-start: 0;
    margin-block-start: 2rem;
    position: absolute;
    @include breakpoint(medium down) {
      margin-block-start: 0;
      padding-block-end: 1.5rem;
      position: relative;
    }
  }
}

@keyframes line-draw {
  0% {
    inline-size: 0%;
  }

  100% {
    inline-size: 100%;
  }
}

.line-1 {
  &::after {
    animation: line-draw 0.5s ease-in forwards;
    animation-delay: 0.5s;
    border-block-end: 4px $bright-blue solid;
    content: "";
    display: block;
    inline-size: 0%;
  }
}

.line-1-details {
  inline-size: 100%;

  &.line-grow {
    &::after {
      animation: line-draw 0.5s ease-in forwards;
      animation-delay: 0.2s;
      border-block-end: 4px $bright-blue solid;
      content: "";
      display: block;
      inline-size: 0%;
    }
  }
}

.line-2 {
  &::after {
    animation: line-draw 0.2s ease-in forwards;
    animation-delay: 0.7s;
    border-block-end: 4px $bright-blue solid;
    content: "";
    display: block;
    inline-size: 0%;
  }
}

.line-3 {
  &::after {
    animation: line-draw 0.2s ease-in forwards;
    animation-delay: 0.7s;
    border-block-end: 4px $bright-blue solid;
    content: "";
    display: block;
    inline-size: 0%;
  }
}

/* To fix the animated blue line on mobile devices (the blue line wasn't appearing on any but the 'Overview' tab) - 2018.02.08 (MK) */
@include breakpoint(medium down) {
  .line-1-details {
    &::after {
      animation: line-draw 0.5s ease-in forwards;
      animation-delay: 0.5s;
      border-block-end: 4px #4198d3 solid;
      content: "";
      display: block;
      inline-size: 0%;
    }
  }
}

.show-hide {
  transition: opacity 150ms ease;

  &.ng-enter,
  &.ng-leave-active {
    opacity: 0;
  }

  &.ng-enter-active {
    opacity: 1;
  }
}

// Toggle view
@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.toggle-hide {
  animation: fade 1s ease-in-out forwards;
  display: none;
  opacity: 0;
}

.toggle-show {
  block-size: auto;
  display: block;
  opacity: 1;
}

.button.secondary.sticky-bttn {
  inset-block-start: 500px;
  inset-inline-end: 440px;
  position: fixed;
  transform: translateX($menu-width);
  transition: all 0.2s ease, transform 0.475s ease-out;
  transition-delay: 0s;
  z-index: 5;
  @include breakpoint(medium down) {
    inset-inline-end: 75px;
  }
}

.button.secondary.sticky-bttn.slide-content-open {
  transform: translateX(0);
  @include breakpoint(medium down) {
    .js-show-navigation & {
      transform: translateX(-345px);
    }
  }
}

.tabs-content {
  .bio-list,
  .social-lockup ul {
    list-style: none;
    margin-inline-start: 0;
  }

  .social-lockup {
    display: flex;
    flex-flow: column;
    margin-block-end: 30px;
  }

  .social-lockup ul {
    display: flex;

    li + li {
      margin-inline-start: 20px;
    }
  }
}
