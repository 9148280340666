.grid-container {
  display: flex;
  flex-flow: column;
  inline-size: 100%;
  margin-inline: auto;
  max-inline-size: 1200px;
}

.grid-row {
  display: flex;
  flex-flow: wrap;
}

.col-1 {
  inline-size: 100%;
}

.col-2 {
  inline-size: calc(100% / 2);
}

.col-3 {
  inline-size: calc(100% / 3);
}

.col-4 {
  inline-size: calc(100% / 4);
}

.col-5 {
  inline-size: calc(100% / 5);
}

.col-6 {
  inline-size: calc(100% / 6);
}

[class*="col-"] {
  @include breakpoint(large up) {
    &.col-2-medium {
      inline-size: calc(100% / 2);
    }

    &.col-3-medium {
      inline-size: calc(100% / 3);
    }

    &.col-4-medium {
      inline-size: calc(100% / 4);
    }

    &.col-5-medium {
      inline-size: calc(100% / 5);
    }

    &.col-6-medium {
      inline-size: calc(100% / 6);
    }
  }
}

.box {
  background-color: rgb(210, 210, 210);
  block-size: 300px;
  border: 1px solid rgb(129, 129, 129);
}
