.spotlight-block {
  display: block;
  margin-block-end: rem-calc(40);
  padding-inline-end: rem-calc(20);
  position: relative;
  @include breakpoint (small down) {
    padding-inline-end: 0;
  }

  .spotlight-cta {
    @include body9-font;
    display: block;
    margin-block-end: rem-calc(10);
    text-transform: uppercase;

    &::after {
      color: $secondary-color;
      content: "●";
      font-size: rem-calc(20);
      inset-block-start: -8px;
      margin-inline-start: rem-calc(5);
      position: absolute;
      @media screen and (device-width: 360px) and (device-height: 640px) and (device-pixel-ratio: 3) and (orientation: portrait) {
        font-size: rem-calc(10);
        inset-block-start: 1px;
        position: absolute;
      }
      @media (min-device-pixel-ratio: 3) and (min-resolution: 192dpi) {
        font-size: rem-calc(10);
        inset-block-start: 1px;
        position: absolute;
      }
    }
  }

  a {
    color: $white;
    text-decoration: none;

    &:hover {
      color: $text-link-blue;
    }
  }

  h4 {
    margin-block-end: rem-calc(5);
  }

  .spotlight-head {
    color: $white;
    display: block;
    font-family: "NHaasGroteskDSStd-45Lt", Helvetica, Arial, sans-serif;
    font-size: rem-calc(20);
    font-style: italic;
    margin-block-end: rem-calc(15);
  }
}

.hr-break+.spotlight-block {
  margin-block-end: 0;
}

.latest-block {
  display: block;
  margin: rem-calc(30) 0;
  position: relative;

  p {
    color: $white;
  }

  a {
    color: $white;
    text-decoration: none;

    &:hover {
      color: #ccc;
    }
  }

  h4 {
    margin-block-end: rem-calc(5);
  }

  p:last-child {
    /* added to get rid of the "jump" after the latest-block animates-in - 2017.12.04 (MK) */
    margin-block-end: 0;
  }

  .article-cta-head {
    color: $white;
    display: block;
    font-family: "NHaasGroteskDSStd-45Lt", Helvetica, Arial, sans-serif;
    font-size: rem-calc(20);
    font-style: italic;
    margin-block-end: rem-calc(15);
  }
}

.white {
  .latest-block {
    a {
      color: $primary-color !important;
      text-decoration: none;

      a:hover {
        color: #ccc;
      }
    }
  }
}

.date-block {
  margin-block-start: rem-calc(10);
  //display: inline-block;
  position: relative;

  p {
    margin-block-end: rem-calc(30);
  }

  p.strong {
    @include body7-font;
    margin-block-end: rem-calc(5);
  }

  h6 {
    @include body7-font;
  }
}

.date-block {
  &.related {
    margin-block-end: -0.75rem;

    p {
      margin-block-end: 0.4rem;
    }
  }
}

.insights-block {
  display: block;
  margin-block-end: rem-calc(60);
  position: relative;
}

.work-block {
  display: block;
  margin-block-end: rem-calc(30);
  position: relative;
}

.recognition-block {
  display: block;
  margin-block-end: rem-calc(30);
  position: relative;

  ul {
    margin-block-end: -5px;
    margin-inline-start: 30px;
    list-style-image: url("/assets/images/bullet.png") !important;

    li {
      padding-block-end: rem-calc(3);

      &:last-child {
        padding-block-end: 0;
      }
    }
  }
}

.positions-block {
  display: block;
  margin-block-end: rem-calc(15);
  position: relative;

  .strong {
    @include body7-font;
  }

  p {
    @include body8-font;
    margin: 0;
  }
}

.guides-block {
  display: inline-block;
  margin-block-end: rem-calc(40);
  position: relative;

  h3 {
    margin-block-end: 10px;
  }

  .guides-cta {
    @include body9-font;
    display: block;
    margin-block-end: rem-calc(10);
    text-transform: uppercase;
  }

  a {
    color: $white;
    text-decoration: none;

    &:hover {
      color: $text-link-blue;
    }
  }
}

.guides-block+.hr-break {
  margin-block-end: 0;
}

.article-cta {
  @include body9-font;
  display: block;
  line-height: 1.2;
  margin-block-end: rem-calc(10);
  text-transform: uppercase;

  .white & {
    color: $primary-color;
  }
}

.academy-block {
  border-block-end: 1px #606b77 solid;
  display: block;
  margin-block-end: rem-calc(42);
  padding-block-end: rem-calc(14);
  @include breakpoint (small down) {
    margin-block-end: rem-calc(30);
    padding-block-end: rem-calc(30);
  }

  a {
    @include body8-font;
    color: $white;
    padding: 0;
    text-decoration: none; 

    &:hover {
      opacity: 0.5;
    }

    h4 {
      &:hover {
        opacity: 0.5;
      }
    }
  }

  p {
    @include breakpoint (small down) {
      line-height: 1.5;
      margin-block-end: rem-calc(5);
    }
  }

  h4 {
    margin-block-end: rem-calc(30);
  }
  
}

.white {
  .academy-block {
    border-block-end: 1px #ccc solid;
    margin-block-start: rem-calc(42);
  }
}

.repwork-block {
  p.strong {
    color: $primary-color !important; /* expertise-Rep Work-title - 2018.02.27 (MK) */
    margin-block-end: 10px;
  }
}


/* added to animate-in the latest-block articles on-scroll - 2017.12.04 (MK) */
.animate-if.ng-enter,
.animate-if.ng-leave {
  transition: opacity 500ms ease, max-height 4s ease;
  transition: opacity 500ms ease, max-height 4s ease;
  transition: opacity 500ms ease, max-height 4s ease;
  transition: opacity 500ms ease, max-height 4s ease;
  transition: opacity 500ms ease, max-height 4s ease;
}

.animate-if.ng-enter {
  max-block-size: 0;
  opacity: 0;
  overflow: hidden;
}

.animate-if.ng-enter.ng-enter-active {
  max-block-size: 2000px;
  opacity: 1;
}

.animate-if.ng-leave {
  max-block-size: 2000px;
  opacity: 1;
}

.animate-if.ng-leave.ng-leave-active {
  max-block-size: 0;
  opacity: 1;
}
