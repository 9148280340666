.careers .inpage-section-block h2 {
  font-family: "NHaasGroteskDSStd-45Lt", Helvetica, Arial, sans-serif;
  letter-spacing: 1.2px;
}

#careers-hero-banner {
  background: url("/assets/images/careers-profile-background-1.jpg") no-repeat;
  background-size: contain;
  block-size: rem-calc(600);
  display: block;
  inline-size: 100%;
  position: relative;
  @media all and (min-width: 1430px) {
    background-size: cover;
  }
}

.career-inquiry-block {
  @include breakpoint(small down) {
    margin: rem-calc(20) 0;
  }

  p {
    @include body3-font;
  }
}

// Students Landing Styles //
.students-hero {
  background: $primary-color;
  display: block;
  padding: rem-calc(100) 12%;
  @include breakpoint(large down) {
    padding-inline: 8%;
  }
  @include breakpoint(medium down) {
    padding-inline: 5%;
  }
  @include breakpoint(small down) {
    padding-inline: 6%;
  }

  h1,
  h2 {
    @include displayXXL-font;
    color: $white !important;
  }
}

.students-intro {
  background: $white;
  display: block;
  padding: rem-calc(70) 12%;
  @include breakpoint(large down) {
    padding-inline: 8%;
  }
  @include breakpoint(medium down) {
    padding-inline: 5%;
  }
  @include breakpoint(small down) {
    padding-inline: 6%;
  }

  img {
    inline-size: 100%;
  }
}

.students-who-you-are {
  background: rgba(199, 202, 207, 0.15);
  display: block;
  padding: rem-calc(70) 12%;
  @include breakpoint(large down) {
    padding-inline: 8%;
  }
  @include breakpoint(medium down) {
    padding-inline: 5%;
  }
  @include breakpoint(small down) {
    padding-inline: 6%;
  }

  .backgrounds-infograph {
    block-size: auto;
    display: inline-block;
    inline-size: 100%;
    margin: 70px 0;
    white-space: nowrap;
    @media all and (max-width: 1023px) {
      margin: 0;
    }

    .backgrounds-hover-box {
      background: $white;
      block-size: rem-calc(150);
      color: $primary-color;
      display: inline-block;
      inline-size: 13.5%;
      margin: 0;
      position: relative;
      white-space: normal;
      @media all and (max-width: 1024px) {
        background: $secondary-color;
        inline-size: 100%;
        margin-block-end: 20px;

        &:lang(fr) {
          block-size: 200px;
        }
      }
      @media all and (max-width: 640px) {
        margin-block-end: 10px;
      }
      @media all and (max-width: 720px) {
        block-size: rem-calc(200);
      }

      span {
        color: $primary-color;
        font-family: "NHaasGroteskDSStd-45Lt", Helvetica, Arial, sans-serif;
        font-size: rem-calc(14);
        font-style: italic;
        inset-block-start: 12px;
        inset-inline-start: 12px;
        line-height: 1.2;
        position: absolute;
        word-wrap: break-word;
        z-index: 50;
        @media all and (max-width: 1302px) {
          font-size: rem-calc(14);
          inset-inline-start: 6px;
        }
        @media all and (max-width: 1170px) {
          inset-inline-start: 7px;
        }
        @media all and (max-width: 1080px) {
          font-size: rem-calc(11);
          inset-inline-start: 6px;
        }
        @media all and (max-width: 1023px) {
          display: block;
          font-size: rem-calc(16);
          inset-inline-start: 6px;
          padding-inline-start: 5px;
          position: relative;

          &:lang(fr) {
            font-size: rem-calc(16);
          }
        }
      }

      .yellow-box {
        background: $secondary-color;
        block-size: 0%;
        inline-size: 100%;
        inset-block-end: 0;
        overflow: hidden;
        position: absolute;
        transition: height 0.3s ease-in-out;

        p {
          @include footer-font;
          line-height: 1.3;
          opacity: 0;
          padding-block-start: 38px;
          padding-inline: 12px;
          transition: opacity 0.4s ease-in-out;
          transition-delay: 0.2s;
          @media all and (max-width: 1302px) {
            padding-inline-start: 6px;
          }
          @media all and (min-width: 1340px) {
            font-size: rem-calc(12);
          }
        }
      }

      &:hover {
        .yellow-box {
          block-size: 100%;

          p {
            opacity: 1;
          }
        }
      }
      @media all and (max-width: 1024px) {
        p {
          display: block;
          line-height: 1.6;
          margin-block-start: 20px;
          padding-inline: 10px;
        }
      }

      .double-line {
        @media all and (max-width: 1488px) {
          margin-block-start: 15px;
        }
      }
    }

    .box-1 {
      inset-block-start: 0;
      @media all and (min-width: 1024px) and (max-width: 1228px) {
        p {
          margin-block-start: rem-calc(15);
        }
      }
    }

    .box-2 {
      inset-block-start: 50px;
    }

    .box-3 {
      inset-block-start: -70px;
    }

    .box-4 {
      inset-block-start: 20px;
    }

    .box-5 {
      inset-block-start: -50px;
    }

    .box-6 {
      inset-block-start: 50px;
    }

    .box-7 {
      inset-block-start: 0;
    }
  }

  p.strong {
    color: $primary-color !important; /* the "Educational backgrounds" title on the student-program page - 2018.02.27 (MK) */
  }
}

.students-accomplish {
  background: $primary-color;
  display: block;
  padding: rem-calc(70) 12%;
  @include breakpoint(large down) {
    padding-inline: 8%;
  }
  @include breakpoint(medium down) {
    padding-inline: 5%;
  }
  @include breakpoint(small down) {
    padding-inline: 6%;
  }

  h2,
  h3,
  p {
    color: $white !important;
  }

  h4 {
    color: $white !important;
  }

  .line-infographs {
    margin-block: rem-calc(70);

    p {
      @include body3-font;
      margin-block: rem-calc(15) rem-calc(58);
      @include breakpoint(small down) {
        font-size: rem-calc(12);
      }
    }

    .animated-gif {
      block-size: 80px;
      inline-size: 80px;
    }
  }
}

.students-jumpstart {
  padding: rem-calc(70) 0;

  h3 {
    display: block;
    margin-block-end: rem-calc(40);
    padding: 0 12%;
    @include breakpoint(large down) {
      padding-inline: 8%;
    }
    @include breakpoint(medium down) {
      padding-inline: 5%;
    }
    @include breakpoint(small down) {
      padding-inline: 6%;
    }
  }
}

.students-unique {
  background: $white;
  color: $black;
  display: block;
  padding: rem-calc(70) 12%;
  @include breakpoint(large down) {
    padding-inline: 8%;
  }
  @include breakpoint(medium down) {
    padding-inline: 5%;
  }
  @include breakpoint(small down) {
    padding-inline: 6%;
  }

  .twitter-block {
    margin-block-start: rem-calc(70);

    img {
      inline-size: 50%;
      @include breakpoint(small down) {
        inline-size: 100%;
      }
    }

    .twitter-text {
      background: $secondary-color;
      display: block;
      inline-size: 100%;
      padding: 5%;

      & > .row > .columns {
        display: flex;
        align-items: center;

        a {
          margin-inline-start: 10px;
          margin-block: 10px;
        }
      }

      span {
        @include body4-font;
        color: $primary-color;
        display: block;
      }

      .instagram-tag {
        @include body7-font;
        border-block-end: 4px #fff solid;
        display: inline-block;
        margin-block-start: rem-calc(30);
        margin-inline-start: rem-calc(15);
      }

      .instagram-tag-2 {
        @include body7-font;
        border-block-end: 4px #fff solid;
        display: inline-block;
        margin-block-start: rem-calc(15);
        margin-inline-start: rem-calc(50);
      }

      .instagram-icon {
        display: inline-block;
        inline-size: 30px;
      }
    }
  }

  .city-social {
    display: inline-block;
    margin-block-end: 0;

    li {
      display: inline-block;
      list-style: none;
      padding-inline-end: 10px;

      a {
        &:hover {
          opacity: 0.5;
        }
      }

      span {
        @include body7-font;
        display: block;
        margin-block-start: -3px;
      }

      img {
        inline-size: 20px;
      }
    }
  }

  h5 {
    font-family: "NHaasGroteskDSStd-65Md", Helvetica, Arial, sans-serif;
    font-size: rem-calc(18);
  }
}

.students-quote {
  background: $primary-color;
  display: block;
  padding: rem-calc(70) 12%;
  @include breakpoint(large down) {
    padding-inline: 8%;
  }
  @include breakpoint(medium down) {
    padding-inline: 5%;
  }
  @include breakpoint(small down) {
    padding-inline: 6%;
  }

  p.lead,
  h6 {
    color: $white !important;
  }
}

.students-meet {
  background: $white;
  display: block;
  padding: rem-calc(70) 12%;
  @include breakpoint(large down) {
    padding-inline: 8%;
  }
  @include breakpoint(medium down) {
    padding-inline: 5%;
  }
  @include breakpoint(small down) {
    padding-inline: 6%;
  }

  .students-rotation {
    display: block;
    margin-block-start: rem-calc(40);

    .button.secondary:hover {
      opacity: 0.7;
    }
  }

  .students-rotate-nav {
    display: block;
    margin-block-end: rem-calc(40);
  }
}

.students-faq {
  background: $white;
  display: block;
  padding: 0 12%;

  @include breakpoint(large down) {
    padding-inline: 8%;
  }
  @include breakpoint(medium down) {
    padding-inline: 5%;
  }
  @include breakpoint(small down) {
    padding-inline: 6%;
  }
}

.students-city {
  background: $white;
  display: block;
  padding: 35px 12%;
  position: relative;
  //white-space: nowrap;
  @include breakpoint(large down) {
    padding-inline: 8%;
  }
  @include breakpoint(medium down) {
    padding-inline: 5%;
  }
  @include breakpoint(small down) {
    padding-inline: 6%;
  }

  .student-city-block {
    display: inline-block;
    float: inline-start;
    inline-size: 49%;
    position: relative;
    white-space: nowrap;
    @include breakpoint(small down) {
      display: block;
      inline-size: 100%;
      margin-block-end: 10px;
    }

    .button {
      inset-block-end: rem-calc(40);
      margin-inline-start: rem-calc(40);
      position: absolute;
      z-index: 20;
    }

    img {
      inline-size: 100%;
    }

    a:hover {
      opacity: 0.7;
    }
    @media screen and (min-width: 640px) and (max-width: 800px) {
      /* fix the text in the Toronto/Montreal "How to apply" links which were running over outside the image */
      a {
        font-size: 12px;
        margin-inline-start: 2rem !important;
      }
    }
    @media screen and (min-width: 1025px) and (max-width: 1185px) {
      /* fix the text in the Toronto/Montreal "How to apply" links which were running over outside the image */
      a {
        font-size: 12px;
        margin-inline-start: 2rem !important;
      }
    }
  }

  .toronto-img {
    margin-inline-end: 10px;
  }
}

// Students City Styles //
.students-city-intro {
  background: url(/assets/images/Students-Toronto-Background.png) $primary-color;
  background-position: center;
  background-size: cover;
  display: block;
  max-block-size: 650px;
  padding: 100px 12%;
  position: relative;
  @include breakpoint(large down) {
    padding-inline: 8%;
  }
  @include breakpoint(medium down) {
    padding-inline: 5%;
  }
  @include breakpoint(small down) {
    padding-inline: 6%;
  }

  h1,
  h2 {
    color: $white !important;
    font-size: 3.375rem;
    letter-spacing: 1px;
    margin-block-end: rem-calc(90);
    @include breakpoint(medium only) {
      font-size: 2.75rem;
      margin-block-end: rem-calc(70);
    }
  }
  @keyframes intro-1-slide {
    0% {
      inline-size: 0;
    }

    100% {
      inline-size: 160px;
    }
  }
  @keyframes intro-2-slide {
    0% {
      inline-size: 0;
    }

    100% {
      inline-size: 160px;
    }
  }

  .intro-stat-1,
  .intro-stat-2 {
    white-space: nowrap;
    word-wrap: normal;

    span,
    p {
      white-space: inherit;
      word-wrap: inherit;
    }
  }

  .intro-stat-1 {
    animation: intro-1-slide 1s forwards;
    animation-delay: 1s;
    display: block;
    inline-size: 0;
    margin-block-end: rem-calc(20);
    overflow: hidden;

    span {
      @include data1-font;
      color: $white;
      font-style: italic;
    }

    p {
      @include body4-font;
      color: $white !important;
      margin-block-start: rem-calc(-20);
    }
  }

  .intro-stat-2 {
    animation: intro-2-slide 1s forwards;
    animation-delay: 1.2s;
    display: block;
    inline-size: 0;
    overflow: hidden;

    span {
      @include data1-font;
      color: $white;
      font-style: italic;
    }

    p {
      @include body4-font;
      color: $white !important;
      margin-block-start: rem-calc(-20);
    }
  }
}

// @media screen and (max-width: 700px) and (orientation: landscape) {
// 	/* added to fix the second line sub-text ("Lawyers in Toronto") getting hidden (white-on-white) on small device in landscape mode */
// 	.students-city-intro {
// 		max-height: 650px;
// 	}
// }

.students-city-mobile-intro {
  margin-block-start: rem-calc(50);
  padding-inline: 6%;

  h1,
  h2 {
    font-size: 2.75rem;
    letter-spacing: 1;
  }

  .intro-stat-1 {
    display: block;
    margin-block-end: rem-calc(0);

    span {
      @include data1-font;
      color: $primary-color;
    }

    p {
      @include body4-font;
      color: $primary-color;
      margin-block: rem-calc(-10) 0;
    }
  }

  .intro-stat-2 {
    display: block;

    span {
      @include data1-font;
      color: $primary-color;
    }

    p {
      @include body4-font;
      color: $primary-color;
      margin-block: rem-calc(-10) 0;
    }
  }
}

.students-city-summer-program {
  background: $white;
  block-size: auto;
  clip: rect(auto, auto, auto, auto);
  display: block;
  padding: 70px 12%;
  position: relative;
  @include breakpoint(large down) {
    padding-inline: 8%;
  }
  @include breakpoint(medium down) {
    padding-inline: 5%;
  }
  @include breakpoint(small down) {
    padding: 44px 6%;
  }

  a:hover {
    color: #a7abb3;
    text-decoration: none;
  }
}

.students-city-articling {
  background: $primary-color;
  block-size: auto;
  display: block;
  padding: rem-calc(70) 12%;
  @include breakpoint(large down) {
    padding-inline: 8%;
  }
  @include breakpoint(medium down) {
    padding-inline: 5%;
  }
  @include breakpoint(small down) {
    padding-inline: 6%;
  }

  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  p.strong,
  li {
    color: $white !important;
  }

  .button {
    text-decoration: none;
  }

  a:hover,
  a.button:hover {
    color: $grey-hover !important;
  }

  .rotation-inforgraph {
    block-size: 210px;
    margin-block-start: rem-calc(40);
    overflow: hidden;
  }
  //    TIMELINE ANIMATION
  .date-block {
    block-size: auto;
    display: inline-block;
    inline-size: 9%;
    line-height: 1;
    margin-block-end: rem-calc(20);
    position: relative;
    @media all and (max-width: 400px) {
      inline-size: 8%;
    }

    span {
      @include footer-font;
      color: $white;
      display: block;
      text-transform: uppercase;

      &::before {
        color: $white;
        content: ("|");
        inset-block-start: 12px;
        opacity: 0.3;
        position: absolute;
      }
    }
  }
  //CYCLE 1 KEYFRAMES
  @keyframes c1-tax-grow {
    0% {
      inline-size: 0%;
    }

    100% {
      inline-size: 19%;
    }
  }
  @keyframes c1-corp-grow {
    0% {
      inline-size: 0%;
    }

    100% {
      inline-size: 28.5%;
    }
  }
  @keyframes c1-liti-grow {
    0% {
      inline-size: 0%;
    }

    100% {
      inline-size: 45%;
    }
  }

  .date-cycle-1 {
    block-size: 140px;
    display: block;
    inline-size: 100%;

    .upper-graphs {
      block-size: 57px;
      inline-size: 100%;

      span {
        color: $primary-color;
        display: block;
        font-size: rem-calc(14);
        font-style: italic;
        padding-block-start: 5px;
        padding-inline-start: 15px;
        position: absolute;
        @include breakpoint(small only) {
          font-size: rem-calc(12);
          padding-inline-start: 5px;
        }
        @media all and (max-width: 400px) {
          padding-inline-start: 3px;
        }
      }

      .c1-tax-block {
        animation: c1-tax-grow 0.5s forwards;
        background: $secondary-color;
        block-size: 57px;
        display: inline-block;
        inline-size: 0%;
        text-overflow: clip;
      }

      .c1-liti-block {
        animation: c1-liti-grow 0.5s forwards;
        animation-delay: 1s;
        background: $secondary-color;
        block-size: 57px;
        display: inline-block;
        inline-size: 0%;
        margin-inline-start: 28%;
        text-overflow: clip;
      }
    }

    .lower-graphs {
      block-size: 57px;
      inline-size: 100%;

      span {
        color: $primary-color;
        display: block;
        font-size: rem-calc(14);
        font-style: italic;
        padding-block-start: 5px;
        padding-inline-start: 15px;
        position: absolute;
        @include breakpoint(small only) {
          font-size: rem-calc(12);
          padding-inline-start: 5px;
        }
        @media all and (max-width: 400px) {
          padding-inline-start: 3px;
        }
      }

      .c1-corp-block {
        animation: c1-corp-grow 0.5s forwards;
        animation-delay: 0.6s;
        background: $secondary-color;
        block-size: 57px;
        inline-size: 0%;
        margin-inline-start: 19%;
        text-overflow: clip;
      }
    }
  }
  //CYCLE 2 KEYFRAMES
  @keyframes c2-comp-grow {
    0% {
      inline-size: 0%;
    }

    100% {
      inline-size: 28%;
    }
  }
  @keyframes c2-tax-grow {
    0% {
      inline-size: 0%;
    }

    100% {
      inline-size: 10%;
    }
  }
  @keyframes c2-corp-grow {
    0% {
      inline-size: 0%;
    }

    100% {
      inline-size: 28.5%;
    }
  }
  @keyframes c2-corp2-grow {
    0% {
      inline-size: 0%;
    }

    100% {
      inline-size: 28%;
    }
  }
  @keyframes c2-liti-grow {
    0% {
      inline-size: 0%;
    }

    100% {
      inline-size: 45%;
    }
  }

  .date-cycle-2 {
    block-size: 140px;
    display: block;
    inline-size: 100%;

    .upper-graphs {
      block-size: 57px;
      inline-size: 100%;

      span {
        color: $primary-color;
        display: block;
        font-size: rem-calc(14);
        font-style: italic;
        padding-block-start: 5px;
        padding-inline-start: 15px;
        position: absolute;
        @include breakpoint(small only) {
          font-size: rem-calc(12);
          padding-inline-start: 5px;
        }
        @media all and (max-width: 400px) {
          padding-inline-start: 3px;
        }
      }

      .c2-comp-block {
        animation: c2-comp-grow 0.5s forwards;
        background: $secondary-color;
        block-size: 57px;
        display: inline-block;
        inline-size: 0%;
        text-overflow: clip;
      }

      .c2-tax-block {
        animation: c2-tax-grow 0.5s forwards;
        animation-delay: 1.2s;
        background: $secondary-color;
        block-size: 57px;
        display: inline-block;
        inline-size: 0%;
        margin-inline-start: 28%;
        text-overflow: clip;
      }
    }

    .lower-graphs {
      block-size: 57px;
      inline-size: 100%;

      span {
        color: $primary-color;
        display: block;
        font-size: rem-calc(14);
        font-style: italic;
        padding-block-start: 5px;
        padding-inline-start: 15px;
        position: absolute;
        @include breakpoint(small only) {
          font-size: rem-calc(12);
          padding-inline-start: 5px;
        }
        @media all and (max-width: 400px) {
          padding-inline-start: 3px;
        }
      }

      .c2-corp-block {
        animation: c2-corp-grow 0.5s forwards;
        animation-delay: 0.6s;
        background: $secondary-color;
        block-size: 57px;
        display: inline-block;
        inline-size: 0%;
        margin-inline-start: 28%;
        text-overflow: clip;
      }

      .c2-corp2-block {
        animation: c2-corp2-grow 0.5s forwards;
        animation-delay: 1.7s;
        background: $secondary-color;
        block-size: 57px;
        display: inline-block;
        inline-size: 0%;
        margin-inline-start: 9.5%;
        text-overflow: clip;
      }
    }
  }
  //CYCLE 3 KEYFRAMES
  @keyframes c3-corp-grow {
    0% {
      inline-size: 0%;
    }

    100% {
      inline-size: 57.5%;
    }
  }
  @keyframes c3-real-grow {
    0% {
      inline-size: 0%;
    }

    100% {
      inline-size: 37%;
    }
  }

  .date-cycle-3 {
    block-size: 140px;
    display: block;
    inline-size: 100%;

    .upper-graphs {
      block-size: 57px;
      inline-size: 100%;

      span {
        color: $primary-color;
        display: block;
        font-size: rem-calc(14);
        font-style: italic;
        padding-block-start: 5px;
        padding-inline-start: 15px;
        position: absolute;
        @include breakpoint(small only) {
          font-size: rem-calc(12);
          padding-inline-start: 5px;
        }
        @media all and (max-width: 400px) {
          padding-inline-start: 3px;
        }
      }

      .c3-corp-block {
        animation: c3-corp-grow 0.5s forwards;
        background: $secondary-color;
        block-size: 57px;
        display: inline-block;
        inline-size: 0%;
        text-overflow: clip;
      }
    }

    .lower-graphs {
      block-size: 57px;
      inline-size: 100%;

      span {
        color: $primary-color;
        display: block;
        font-size: rem-calc(14);
        font-style: italic;
        padding-block-start: 5px;
        padding-inline-start: 15px;
        position: absolute;
        @include breakpoint(small only) {
          font-size: rem-calc(12);
          padding-inline-start: 5px;
        }
        @media all and (max-width: 400px) {
          padding-inline-start: 3px;
        }
      }

      .c3-real-block {
        animation: c3-real-grow 0.5s forwards;
        animation-delay: 0.6s;
        background: $secondary-color;
        block-size: 57px;
        display: inline-block;
        inline-size: 0%;
        margin-inline-start: 57.5%;
        text-overflow: clip;
      }
    }
  }
}

.students-city-applying {
  background: $white;
  display: block;
  padding: 70px 12%;
  position: relative;
  @include breakpoint(large down) {
    padding-inline: 8%;
  }
  @include breakpoint(medium down) {
    padding-inline: 5%;
  }
  @include breakpoint(small down) {
    padding-inline: 6%;
  }

  p.lead {
    margin-block-end: rem-calc(70);
  }

  .students-apply-detail {
    margin-block-end: rem-calc(20);

    p {
      @include body8-font;
      color: $primary-color;
      margin-block-end: rem-calc(5);
      @include breakpoint(small down) {
        line-height: 1.5;
      }
    }
  }

  .due-date {
    @include body7-font;
  }

  .students-rotation {
    display: block;
    margin-block-start: rem-calc(40);
  }

  .students-rotate-nav {
    display: block;
    margin-block-end: rem-calc(40);
  }
}

.students-city-key-contacts {
  background: $white;
  display: block;
  padding: 35px 12%;
  position: relative;
  @include breakpoint(large down) {
    padding-inline: 8%;
  }
  @include breakpoint(medium down) {
    padding-inline: 5%;
  }
  @include breakpoint(small down) {
    padding-inline: 6%;
  }
}

.key-contacts-info .key-contact-name a:hover {
  opacity: 0.7;
}

.large-accordion {
  $accordion-height: 135;
  $accordion-height-medium: 115;
  $accordion-height-small: 100;
  $title-size: 32;
  $title-size-medium: 25;
  $title-size-small: 22;
  $title-size-xsmall: 19;
  $icon-height: 56;
  $xsmall: 380px;
  background: rgba(199, 202, 207, 0.15);
  display: inline-block;
  inline-size: 100%;
  max-block-size: rem-calc($accordion-height);
  overflow: hidden;
  padding: rem-calc(($accordion-height - ($title-size * 0.85)) / 2) 0;
  position: relative;
  transition: all 0.5s ease-in-out;
  @include breakpoint(medium down) {
    max-block-size: rem-calc($accordion-height-medium);
    padding: rem-calc(
        ($accordion-height-medium - ($title-size-medium * 0.85)) / 2
      )
      0;
  }
  @include breakpoint(small down) {
    max-block-size: rem-calc($accordion-height-small);
    padding: rem-calc(
        ($accordion-height-small - ($title-size-small * 0.85)) / 2
      )
      0;
  }
  @include breakpoint($xsmall down) {
    max-block-size: rem-calc($accordion-height-small);
    padding: rem-calc(
        ($accordion-height-small - ($title-size-xsmall * 0.85)) / 2
      )
      0;
  }

  & span::after {
    content: url(/assets/images/large-accordion-plus-icon.png);
    inset-block-start: rem-calc($accordion-height - $icon-height) / 2;
    inset-inline-end: 90px;
    position: absolute;
    @include breakpoint(medium down) {
      inset-block-start: rem-calc($accordion-height-medium - $icon-height) / 2;
      inset-inline-end: 30px;
    }
    @include breakpoint(small down) {
      inset-block-start: rem-calc($accordion-height-small - $icon-height) / 2;
      transform: scale(0.75);
    }
  }

  span {
    @include displayS-font;
    color: $primary-color;
    display: flex;
    inline-size: 100%;
    line-height: 0.85;
    margin-block-end: 10px;
    padding: 0 rem-calc(150) 0 12%;
    @include breakpoint(large down) {
      padding-inline-start: 8%;
    }
    @include breakpoint(medium down) {
      font-size: rem-calc($title-size-medium);
      letter-spacing: -1px;
      padding-inline: 5% rem-calc(90);
    }
    @include breakpoint(small down) {
      font-size: rem-calc($title-size-small);
      padding-inline-end: rem-calc(75);
    }
    @include breakpoint($xsmall down) {
      font-size: rem-calc($title-size-xsmall);
    }
  }

  p {
    display: block;
    inline-size: 60%;
    margin-block-end: 0;
    margin-inline-start: 15%;
    opacity: 0;
    transition: opacity 0.3s ease-in;
    @include breakpoint(medium down) {
      inline-size: 67.5%;
      line-height: 1.4;
      margin-block-start: 1.5em;
      margin-inline-start: 12.5%;
    }
    @include breakpoint(small down) {
      inline-size: 75%;
      margin-inline-start: 10%;
    }
  }

  &:hover {
    background: $secondary-color;

    & span::after {
      content: url("/assets/images/large-accordion-plus-icon-white.png");
    }
  }
  .toggle-button {
    cursor: pointer;
  }

  .content-to-expand {
    display: none;
  }
}

.large-accordion.expanded {
  background: $secondary-color;
  max-block-size: rem-calc(1150);

  .content-to-expand {
    display: block;
  }

  p {
    display: block;
    opacity: 1;
  }

  span::after {
    content: url("/assets/images/large-accordion-minus-icon.png");
    margin-block-start: 10px;
  }
}
