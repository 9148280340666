.back-to-top {
  $size: 25;
  background-color: transparent;
  background-image: url("/assets/images/back-to-top.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 80%;
  block-size: rem-calc($size);
  cursor: pointer;
  display: block;
  inline-size: rem-calc($size);
  inset-block-end: rem-calc(15);
  inset-inline-end: rem-calc($menu-size-medium);
  margin-inline-end: rem-calc(15);
  opacity: 0;
  pointer-events: none;
  position: fixed;
  text-indent: -99999px;
  transition: opacity 300ms ease;
  @include breakpoint(medium down) {
    inset-inline-end: 0;
  }

  &.js-show {
    opacity: 1;
    pointer-events: all;
  }
}
